import React from 'react'
import PropTypes from 'prop-types'
import { PaperCard } from '../../components'
import { makeStyles } from '@material-ui/core/styles'
import { FormLabel, Grid, CircularProgress } from '@material-ui/core'
import { fetcherSWR } from '../../services/api/fetcher'
import { formatNumber } from 'intl-number-helper'
import EmailButton from '../../components/button/emailButton'
import { format } from 'date-fns'
import { enUS } from 'date-fns/locale'
import SavingsAccountStatement from './table'
import useLoanInfo from '../../components/hooks/useLoanInfo'
import NoData from '../../components/typography/no-data'

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: "16px",
        color: theme.palette.grey[800],
    },
    value: {
        fontSize: "14px",
        color: theme.palette.grey[600],
    }
}))

const getFinalBalance = (accountStatus) => {
    return accountStatus ? accountStatus.length > 0 ? accountStatus[accountStatus.length - 1].Saldo : 0 : 0
}

const Index = ({ userAuth }) => {
    const classes = useStyles();
    const [loading, setLoading] = React.useState(false);
    const [saldo, setSaldo] = React.useState(0);
    const today = new Date();
    const firstDateYear = `${today.getFullYear()}-01-01`;
    const currentDate = format(today, "yyyy-MM-dd", { locale: enUS });
    const { isLoading, data , error } = useLoanInfo(userAuth.IdEmpresaConvenio, userAuth.sub);

    const onClickHandler = () => {
        setLoading(true);
        if(data)
            fetcherSWR(`/api/registrarprestamosahorro/estadocuentaemail/${userAuth.IdEmpresaConvenio}/${userAuth.sub}/${data.TipoProductoFinancieroId}/${firstDateYear}/${currentDate}`).then(d => {
                setLoading(false)
            })
    }
    const saldoHandler = (accountStatus) => {
        setSaldo(getFinalBalance(accountStatus));
    }

    if (error) {
        console.log(error);
        return <p>Surgió un error al obtener el estado de cuenta del ahorro</p>
    }

    if (isLoading)
        return <CircularProgress />


    return (
        <PaperCard>
            {!data ? <NoData text="El usuario no cuenta con ahorro" /> :
                <Grid container direction={"column"} spacing={1}>
                    <Grid item>
                        <FormLabel className={classes.label}>Plan de Ahorro</FormLabel>
                    </Grid>
                    <Grid item>
                        <FormLabel className={classes.value}>{data.PlanAhorro}</FormLabel>
                    </Grid>
                    <Grid item>
                        <FormLabel className={classes.label}>Saldo</FormLabel>
                    </Grid>
                    <Grid item>
                        <FormLabel className={classes.value}>{formatNumber(saldo, "en-US", "$")}</FormLabel>
                    </Grid>
                    <Grid container justify={"flex-end"}>
                        {loading ? <CircularProgress /> : <EmailButton onClick={onClickHandler} />}
                    </Grid>
                    <Grid item xs={12} spacing={4}>
                        {data && <SavingsAccountStatement
                            onSaldoChange={saldoHandler}
                            userid={userAuth.sub}
                            empresaconvenioid={userAuth.IdEmpresaConvenio}
                            savingPlan={data}
                            currentDate={currentDate}
                            firstDateYear={firstDateYear}
                        />}
                    </Grid>
                </Grid>}
        </PaperCard>
    )
}

Index.propTypes = {
    userAuth: PropTypes.object.isRequired
}

export default Index
