import React from 'react'
import Helmet from 'react-helmet'
import { Application } from '../views/loan'
import Heading from '../components/typography/heading'
import PaperCard from '../components/paper'
import AuthContext from '../components/auth/context'
import { useAuth } from '../components/auth/useAuth'

const LoanHistory = () => {
    const { UserInfo } = React.useContext(AuthContext)
    const userAuth = useAuth(UserInfo())
    
    return (
        <>
            <Helmet title={'Historial de Solicitudes'} />
            <Heading  text={"Historial de Solicitudes"}/>
            <PaperCard>
                {userAuth && userAuth.sub && <Application userAuth={userAuth} />} 
            </PaperCard>
        </>
    )
}

export default LoanHistory
