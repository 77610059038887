import { fetcherReactQuery, MutationReactQuery } from '../../services/api/fetcher';

const fetchApplicationBankInfo = async (id) => {
    const response = await fetcherReactQuery(`/api/solicitudadmin/${id}/datosbancarios`);
    return response.data;
}

const fetchMutationBankInfo = async (solicitud) => {
    const response = await MutationReactQuery(
        "post",
        `/api/solicitudadmin/${solicitud.Id}/datosbancarios`,
        solicitud)
    return response.data
}

export { 
    fetchApplicationBankInfo,
    fetchMutationBankInfo
}