import React from 'react'
import PropTypes from 'prop-types'
import { Table, TableBody, TableCell, TableHead, TableRow } from '@material-ui/core'
import { formatNumber } from 'intl-number-helper'
import TableResponsive from '../../../components/tableresponsive'
import withStyles from '@material-ui/styles/withStyles'

const ReportTable = props => {
    const { classes, data } = props

    return (
        <TableResponsive>
            <Table aria-label="amortization table">
                <TableHead>
                    <TableRow>
                        <TableCell align="left" className={classes.header}>#</TableCell>
                        <TableCell align="left" className={classes.header}>INDICADOR</TableCell>
                        <TableCell align="right" className={classes.header}>MONTO</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => {
                        return (
                            <TableRow key={index}>
                                <TableCell align="left" component="th" scope="row">{row.IndicadoresClave.Clave}</TableCell>
                                <TableCell align="left">{row.IndicadoresClave.Descripcion}</TableCell>
                                <TableCell align="right">{formatNumber(row.Monto, 'en-US', '')}</TableCell>
                            </TableRow>
                        )
                    }
                    )}
                </TableBody>
            </Table>
        </TableResponsive>
    )
}

ReportTable.propTypes = {
    data: PropTypes.array,
    classes: PropTypes.object.isRequired
}

export default withStyles(withStyles)(ReportTable)
