import * as Yup from 'yup'

const validationSchema = Yup.object().shape({
    rfc: Yup.string()
        .max(13, "El Rfc no debe ser mayor a 13 caracteres")
        .matches(/^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/, "El rfc no tiene el formato correcto")
        .required("El Rfc es requerido"),

    email: Yup.string()
        .email("El correo electrónico no tiene el formato correcto")
        .required("El correo electrónico es requerido")
})

export default validationSchema