// import { fetcherSWR } from '../../services/api/fetcher'
// import useSWR from 'swr'

// const fetchTipoProductoFinanciero = async (api) => {
//     return await fetcherSWR(api)
// }

// const useTipoProductoFinanciero = (IdEmpresaConvenio) => {
//     return useSWR(IdEmpresaConvenio ? [`/api/ObtenerTipoProductoDD/${IdEmpresaConvenio}`] : null, fetchTipoProductoFinanciero)
// }

// export default useTipoProductoFinanciero

import { fetcherReactQuery } from '../../services/api/fetcher'
import { useQuery } from 'react-query'
const fetch = async (api, id) => {
    const response = await fetcherReactQuery(`${api}/${id}`)
    return response.data
}
const useTipoProductoFinanciero = (IdEmpresaConvenio) => {
    return useQuery([`/api/ObtenerTipoProductoDD/`, IdEmpresaConvenio], fetch, {
        staleTime: 300000
    })
}
export default useTipoProductoFinanciero