import React from 'react'
import PropTypes from 'prop-types'
import Form from './form'

const Index = (props) => {
    const { handleCloseModal, userAuth } = props;
    return (
        <Form handleCloseModal={handleCloseModal} userAuth={userAuth}/>
    )
}

Index.propTypes = {
    handleCloseModal: PropTypes.func
}

export default Index
