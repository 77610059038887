import { fetcherReactQuery } from '../../services/api/fetcher';

const fetchNivelEducativoActive = async () => {
    const response = await fetcherReactQuery(`/api/nivelesacademicos/active`);
    return response.data;
}


export { 
    fetchNivelEducativoActive
}