import React from 'react'
import PropTypes from 'prop-types'
import { InputLabel, Select, CircularProgress, FormControl } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import useNominaType from '../../../components/hooks/useNominaType'

const useStyles = makeStyles((theme) => ({
    formControl: {
        marginTop: "20px",
        width: "100%"
    },
    selectEmpty: {
        marginTop: theme.spacing(2),
    },
}));


const SelectLoanType = props => {
    const classes = useStyles()
    const { empresaconvenioid, onChange } = props
    const [state, setState] = React.useState({
        nominatypeid: ''
    })
    const { data, isLoading } = useNominaType(empresaconvenioid ? empresaconvenioid : 0, false)

    const handleChange = (event) => {
        const name = event.target.name;
        setState({
            ...state,
            [name]: event.target.value,
        })
        onChange(event.target.value)
    }

    if(!data)
        return <CircularProgress />

    return (
        <FormControl className={classes.formControl}>
            <InputLabel htmlFor="nominatype-native-simple">Tipo de Nómina</InputLabel>
            {!isLoading && 
            <Select
                native
                value={state.age}
                onChange={handleChange}
                inputProps={{
                    name: 'nominatypeid',
                    id: 'nominatype-native-simple',
                }}
            >
                <option aria-label="None" key="-1" value="-1" > Opcional </option>
                {data && data.map( nomina => {
                    return (
                        <option key={nomina.Id} value={nomina.IdNomina}>{nomina.Nombre}</option>
                    )
                })}
            </Select>
            }
        </FormControl>
    )
}


SelectLoanType.propTypes = {
    empresaconvenioid: PropTypes.number,
    onChange: PropTypes.func
}
    

export default SelectLoanType
    