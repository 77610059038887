import { fetcherReactQuery } from '../../services/api/fetcher'
import { useQuery } from 'react-query'

const fetch  = async (api) => {
    const response = await fetcherReactQuery(api)
    return response.data;
}
const useRequiredDocuments = () => {
    return useQuery([`/api/documentosrequeridos/activos`], fetch, {
        refetchOnWindowFocus: false,
        staleTime: 30000
    })
}
export default useRequiredDocuments;
