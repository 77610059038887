import { format } from 'date-fns'
import { es } from 'date-fns/locale'

const Format = (date) => {
    let result = null
    try{ 
        const fecha = date.toString().substring(0, date.toString().length - 8);
        const year = fecha.split("-")[0];
        const month = fecha.split("-")[1];
        let day = fecha.split("-")[2];
        day = day.split("T")[0];
        
        let _date = new Date ( year, month - 1, day );
        result = format(
            _date,
            'dd/MM/yyyy',
            { locale: es })
    }catch(err) {
        console.log(err)
    }
    return result
}

const DateToString = (date, _format) => {
    let result = null
    try{ 
        result = format(
            date,
            _format ? _format : 'yyyy-MM-dd',
            { locale: es })
    }catch(err) {
        console.log(err)
    }
    return result
}

const NetDateToString = (date, _format) => {
    let result = null
    //const fecha = date.toString().substring(0, date.toString().length - 9);
    //let _date = new Date(fecha.split("-")[0],(fecha.split("-")[1]) - 1,fecha.split("-")[2])
    try{ 
        const fecha = date.toString().substring(0, date.toString().length - 9);
        let _date = new Date(fecha.split("-")[0],(fecha.split("-")[1]) - 1,fecha.split("-")[2])
        result = format(
            _date,
            _format,
            { locale: es })
    }catch(err) {
        console.log(err)
    }
    return result
}

export { Format, DateToString, NetDateToString }