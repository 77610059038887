import React from "react";
import { useParams } from "react-router-dom";
import Helmet from "react-helmet";
import Heading from "../components/typography/heading";
import { useAuth } from "../components/auth/useAuth";
import AuthContext from "../components/auth/context";
import { PaperCard } from "../components";
import { CircularProgress } from "@material-ui/core";
import { fetchApplicationBankInfo } from "../components/hooks/useApplicationBankInfo";
import { useQuery } from "react-query";
import BankInfo from "../views/pending-application-view/components/bank-info";
import SolicitudHeader from "../views/pending-application-view/components/solicitud-header";

const ApplicationBankInfo = () => {
  const params = useParams();
  const [id, setId] = React.useState(null);
  const { UserInfo } = React.useContext(AuthContext);
  const userAuth = useAuth(UserInfo());
  const { data, isLoading } = useQuery(["bank-info", id], () =>
    fetchApplicationBankInfo(id || 0),
  );

  React.useEffect(() => {
    if (params) setId(+params.id);
  }, [params]);

  return (
    <>
      <Helmet title={"Corrección de Datos Bancaria"} />
      <Heading text={"Corrección de Datos Bancaria"} />
      {userAuth && userAuth.sub && (
        <PaperCard>
          {isLoading ? (
            <CircularProgress />
          ) : (
            <SolicitudHeader data={data}>
              <BankInfo solicitud={data} />
            </SolicitudHeader>
          )}
        </PaperCard>
      )}
    </>
  );
};
export default ApplicationBankInfo;
