import React from 'react'
import { FormLabel, Grid, CircularProgress } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { PaperCard } from '../../components'
import { formatNumber } from 'intl-number-helper'
import PropTypes from 'prop-types'
import useLoanInfo from '../../components/hooks/useLoanInfo'
import NoData from '../../components/typography/no-data'

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: "16px",
        color: theme.palette.grey[800],
    },
    value: {
        fontSize: "14px",
        color: theme.palette.grey[600],
    }
}));


const Index = (props) => {
    const classes = useStyles()
    const { userAuth } = props
    const { data, isLoading, error } = useLoanInfo(userAuth.IdEmpresaConvenio, userAuth.sub)

    if (error)
        return (
            <Grid item>
                <FormLabel className={classes.label}>El empleado no cuenta con un ahorro</FormLabel>
            </Grid>
        )

    if (isLoading)
        return <CircularProgress />

    return (
        <PaperCard>
            {!data ? <NoData text="El usuario no cuenta con ahorro" /> :
                <Grid container direction={"column"} spacing={1}>
                    <Grid item>
                        <FormLabel className={classes.label}>Plan de Ahorro</FormLabel>
                    </Grid>
                    <Grid item>
                        <FormLabel className={classes.value}>{data.PlanAhorro}</FormLabel>
                    </Grid>
                    <Grid item>
                        <FormLabel className={classes.label}>Meta de Aportación</FormLabel>
                    </Grid>
                    <Grid item>
                        <FormLabel className={classes.value}>{formatNumber(data.MetaAportacion ? data.MetaAportacion : 0, 'en-US', '$')}</FormLabel>
                    </Grid>
                    <Grid item>
                        <FormLabel className={classes.label}>Aportaciones Acumuladas</FormLabel>
                    </Grid>
                    <Grid item className={classes.value}>
                        <FormLabel className={classes.value}>{formatNumber(data.AportacionAcumulada ? data.AportacionAcumulada : 0, 'en-US', '$')} </FormLabel>
                    </Grid>
                    <Grid item>
                        <FormLabel className={classes.label}>Tasa de Ahorro</FormLabel>
                    </Grid>
                    <Grid item className={classes.value}>
                        <FormLabel
                            className={classes.value}>
                            {formatNumber(parseFloat(data.TasaInteres ? data.TasaInteres.split(' ')[1] : 0), 'en-US', '%')}
                        </FormLabel>
                    </Grid>

                    <Grid item>
                        <FormLabel className={classes.label}>Saldo del Ahorro</FormLabel>
                    </Grid>
                    <Grid item className={classes.value}>
                        <FormLabel className={classes.value}>{formatNumber(data.SaldoAhorro ? data.SaldoAhorro : 0, 'en-US', '$')}</FormLabel>
                    </Grid>
                </Grid>
            }
        </PaperCard>
    )
}

Index.propTypes = {
    userAuth: PropTypes.object
}

export default Index


