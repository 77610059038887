import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import { makeStyles } from '@material-ui/core/styles'
import { PaperCard } from '../../components'
import { FormLabel, Grid, CircularProgress, Fab, Button } from '@material-ui/core'
import LoanTypeSelect from './components/loantype-select'
import useFinancialProductType from '../../components/hooks/useFinancialProductType'
import useNominaType from '../../components/hooks/useNominaType'
import { TextField } from '@material-ui/core'
import { Formik } from 'formik'
import validationSchema from './validation'
import { SaveAlt } from '@material-ui/icons'
import NumericTextBox from "../../components/numerictextbox"
import { post } from '../../services/api'
import { toast } from 'react-toastify';
import AmortazationTable from './amortization-table'
import ArrowLeftIcon from '@material-ui/icons/ArrowLeft'

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: "16px",
        color: theme.palette.grey[800],
    },
    value: {
        fontSize: "14px",
        color: theme.palette.grey[600],
    },
    showResult: {
        display: "block"
    },
    hideResult: {
        display: "none",
        border: "1px solid red"
    },
    showFilter: {
        display: "block"
    },
    hideFilter: {
        display: "none"
    }
}))

const initValues = { monto: "", montodescuento: "", montodescuentocompensacion: "" };
const Index = props => {
    const { userAuth } = props
    const classes = useStyles()
    const [state, setState] = React.useState({
        nominaSecondaryType: null,
        loanType: null,
        nominaType: null
    })

    const [amortizationTable, SetAmortizationTable] = React.useState(null)
    const [simulationInfo, SetSimulationInfo] = React.useState(null)
    const { isLoading, data: tipoprestamos } = useFinancialProductType(userAuth.IdEmpresaConvenio)
    const { isLoading: isLoadingNomina, data: nominaType } = useNominaType(userAuth.IdEmpresaConvenio, true)

    const onReturn = useCallback(
        (e) => {
            e.preventDefault()
            SetAmortizationTable(null)
        }, []);

    React.useEffect(() => {
        if (tipoprestamos && tipoprestamos.length > 0)
            setState(prev => ({
                ...prev,
                loanType: tipoprestamos[0]
            })
            )

        if (nominaType && nominaType.length > 0) {
            setState(prev => ({
                ...prev,
                nominaType: nominaType[0]
            })
            )
        }
        return () => { /*isMounted = false*/ };
    }, [tipoprestamos, nominaType, amortizationTable])

    if (isLoading)
        return <CircularProgress />

    if (isLoadingNomina)
        return <CircularProgress />

    const selectNominaHandler = (value) => {
        if (value > 0) {
            setState(prev => ({
                ...prev,
                nominaSecondaryType: value
            }))
        } else {
            setState(prev => ({
                ...prev,
                nominaSecondaryType: null
            }))
        }
    }

    const onSendSimulation = async (payload) => {
        try {
            SetSimulationInfo(payload)
            const response = await post("/api/SimularPrestamo", payload)
            SetAmortizationTable(response.data)
        } catch (err) {
            toast.error("Surgió un error al generear la corrida")
            SetAmortizationTable([])
        }
    }

    return (
        <PaperCard>
            <div className={!amortizationTable ? classes.showFilter : classes.hideFilter}>
                <Formik
                    initialValues={initValues}
                    validationSchema={validationSchema}
                    onSubmit={async (values, { setSubmitting, resetForm }) => {
                        const { monto, montodescuento, montodescuentocompensacion } = values
                        const entity = {
                            Empresa: userAuth.IdEmpresaConvenio,
                            MontoPrestamo: monto ? parseFloat(monto) : 0,
                            ProductoFinanciero: state.loanType.IdProductoFinanciero,
                            MontoPago: montodescuento ? parseFloat(montodescuento) : 0,
                            TipoNomina: state.nominaType.IdNomina,

                            MontoPago2: montodescuentocompensacion ? parseFloat(montodescuentocompensacion) : 0,
                            TipoNomina2: state.nominaSecondaryType ? parseInt(state.nominaSecondaryType) : null
                        }
                        await onSendSimulation(entity)
                        setSubmitting(false)
                    }}
                >
                    {({
                        values,
                        errors,
                        touched,
                        handleChange,
                        handleBlur,
                        handleSubmit,
                        isSubmitting,
                        setFieldValue
                    }) => (
                        <form noValidate onSubmit={handleSubmit}>
                            <Grid container direction={"column"} spacing={1}>
                                <Grid item sm={2}>
                                    <FormLabel className={classes.label}>Tipo de Préstamo</FormLabel>
                                </Grid>
                                <Grid item sm={10}>
                                    <FormLabel className={classes.value}>{state.loanType ? state.loanType.Nombre : ""}</FormLabel>
                                </Grid>
                                {state.nominaType &&
                                    <>
                                        <Grid item>
                                            <FormLabel className={classes.label}>Nómina principal</FormLabel>
                                        </Grid>
                                        <Grid item>
                                            <FormLabel className={classes.value}>{state.nominaType ? state.nominaType.Nombre : ""}</FormLabel>
                                        </Grid>
                                    </>
                                }
                                <Grid item>
                                    <TextField
                                        variant="standard"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="monto"
                                        label="Monto del préstamo"
                                        id="monto"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.monto}
                                        error={touched.monto && errors.monto}
                                        helperText={errors.monto}
                                        InputProps={{
                                            inputComponent: NumericTextBox,
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <TextField
                                        variant="standard"
                                        margin="normal"
                                        required
                                        fullWidth
                                        name="montodescuento"
                                        label="Monto del descuento nómina catorcenal"
                                        id="montodescuento"
                                        onChange={handleChange}
                                        onBlur={handleBlur}
                                        value={values.montodescuento}
                                        error={touched.montodescuento && errors.montodescuento}
                                        helperText={errors.montodescuento}
                                        InputProps={{
                                            inputComponent: NumericTextBox,
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    <LoanTypeSelect
                                        empresaconvenioid={userAuth.IdEmpresaConvenio}
                                        onChange={e => {
                                            selectNominaHandler(e);
                                            setFieldValue("montodescuentocompensacion", 0);
                                        }}
                                    />
                                </Grid>
                                <Grid item>
                                    {state.nominaSecondaryType && state.nominaSecondaryType !== "-1" &&
                                        <TextField
                                            variant="standard"
                                            margin="normal"
                                            fullWidth
                                            label="Monto del descuento nómina de compensación"
                                            name="montodescuentocompensacion"
                                            id="montodescuentocompensacion"
                                            onChange={handleChange}
                                            onBlur={handleBlur}
                                            value={values.montodescuentocompensacion}
                                            error={touched.montodescuentocompensacion && errors.montodescuentocompensacion}
                                            helperText={errors.montodescuentocompensacion}
                                            InputProps={{
                                                inputComponent: NumericTextBox,
                                            }}
                                        />
                                    }
                                </Grid>
                                <Grid item style={{ textAlign: "right" }} sm={12}>
                                    {isSubmitting ? <CircularProgress /> :
                                        <Fab type="submit" size={"medium"} color="primary" title="Calcular simulador">
                                            <SaveAlt />
                                        </Fab>
                                    }
                                </Grid>
                            </Grid>
                        </form>
                    )}
                </Formik>
            </div>

            <div className={amortizationTable ? classes.showResult : classes.hideResult}>
                <Grid container>
                    <Grid item sm={12} style={{ textAlign: "right" }}>
                        <Button variant="contained" color={"secondary"} onClick={onReturn}>
                            <ArrowLeftIcon />
                            Regresar
                        </Button>
                    </Grid>
                    <Grid item sm={12}>
                        <AmortazationTable loanInfo={simulationInfo ? simulationInfo : {}} data={amortizationTable ? amortizationTable : []} />
                    </Grid>
                </Grid>
            </div>
        </PaperCard>
    )
}

Index.propTypes = {
    userAuth: PropTypes.object.isRequired
}

export default Index
