import { fetcherReactQuery } from '../../services/api/fetcher';
import { useQuery } from 'react-query';

const fetch = async (api) => {
    const response = await fetcherReactQuery(api)
    return response.data
}

const useStates = () => {
    return useQuery([`/api/ObtenerEstadosDD`], fetch, { staleTime: 30000 })
}

export default useStates
