import React from 'react';
import { List, ListItem, Divider, Grid, FormLabel, Box, Typography } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Format } from '../../../utils/format'
import { formatNumber } from 'intl-number-helper'
import PropTypes from 'prop-types'
import { FaFileSignature, FaFolderOpen, FaRegFileArchive } from 'react-icons/fa';
import { ESTATUS, estatusStr } from '../estatus';
import { useHistory } from 'react-router-dom';


const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: "16px",
        color: theme.palette.grey[800],
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "14px",
        },
    },
    value: {
        fontSize: "14px",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "12px",
        },
        color: theme.palette.grey[600],
    },
    Division: {
        borderBottom: "1px solid #3F51B5"
    }
}));

const Item = ({estatus, children}) => (
    <Box m={"auto"}
        width={1}
        display="flex"
        flexDirection="row"
        justifyContent="space-between"
        alignItems="center"
        style={{ alignItems:"center" }}>
        <Box>
            <Typography variant="subtitle2" style={{ fontSize: 16 }}>
                {estatusStr(estatus)}
            </Typography>
        </Box>
        <Box>
            {children}
        </Box>
    </Box>)

Item.propTypes = {
    estatus: PropTypes.string,
    children: PropTypes.node
}

const Estatus = ({ item, onEdit }) => {
    return (
        <>
            {item.Estatus === ESTATUS.INCOMPLETO_DOCUMENTOS &&
                <Item estatus={item.Estatus}>
                    <FaFolderOpen size="36" color="#d11e03" style={{ cursor: "pointer" }}
                        onClick={e => { onEdit(item); e.preventDefault(); }}
                    />
                </Item>
            }
            {item.Estatus === ESTATUS.INCOMPLETO_BANCARIO &&
                <Item estatus={item.Estatus}>
                    <FaRegFileArchive size="36" color="#d11e03" style={{ cursor: "pointer" }}
                        onClick={e => { onEdit(item); e.preventDefault(); }} />
                </Item>
            }
            {item.Estatus === ESTATUS.INCOMPLETO_FIRMA &&
                <Item estatus={item.Estatus}>
                    <FaFileSignature size="36" color="#d11e03" style={{ cursor: "pointer" }}
                        onClick={e => { onEdit(item); e.preventDefault(); }} />
                </Item>
            }
        </>
    )
}

Estatus.propTypes = {
    onEdit: PropTypes.func.isRequired,
    item: PropTypes.object.isRequired
}

const Details = ({ data }) => {
    const classes = useStyles();
    const navigate = useHistory();
    
    const onEdit = (e) => {
        if(e.Estatus === ESTATUS.INCOMPLETO_DOCUMENTOS)
            navigate.push(`/admin/application-documents/${e.Id}`);
        if(e.Estatus === ESTATUS.INCOMPLETO_FIRMA)
            navigate.push(`/admin/application-sign/${e.Id}`);
        if(e.Estatus === ESTATUS.INCOMPLETO_BANCARIO)
            navigate.push(`/admin/application-bank-info/${e.Id}`);
        
    }

    
    return (
        <List>
            {data && data.map(item => {
                return (
                    <>
                        <ListItem key={item.Folio}>
                            <Grid container xs={12}>
                                <Grid container>
                                    <Grid item xs={9} md={2}>
                                        <FormLabel className={classes.label}>Fecha</FormLabel>
                                    </Grid>
                                    <Grid item xs={3} md={10}>
                                        <FormLabel className={classes.value}>{Format(item.FechaSolicitud)}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={9} md={2}>
                                        <FormLabel className={classes.label}>Núm Solicitud</FormLabel>
                                    </Grid>
                                    <Grid item xs={3} md={10}>
                                        <FormLabel className={classes.value}>{item.Folio}</FormLabel>
                                    </Grid>
                                </Grid>
                                <Grid container>
                                    <Grid item xs={9} md={2}>
                                        <FormLabel className={classes.label}>Monto</FormLabel>
                                    </Grid>
                                    <Grid item xs={3} md={10}>
                                        <FormLabel className={classes.value}>{formatNumber(item.MontoSolicitado, 'en-US', '$')}</FormLabel>
                                    </Grid>
                                </Grid>
                                {item.Estatus === ESTATUS.REGISTRADO &&
                                    <Grid container>
                                        <Grid item xs={9} md={2}>
                                            <FormLabel className={classes.label}>Estatus</FormLabel>
                                        </Grid>
                                        <Grid item xs={3} md={10}>
                                            <FormLabel className={classes.value}>  {estatusStr(item.Estatus)}</FormLabel>
                                        </Grid>
                                    </Grid>}
                            </Grid>
                        </ListItem>
                        {item.Estatus !== ESTATUS.REGISTRADO &&
                            <ListItem key={"prefix_" + item.Folio}>
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Estatus item={item} onEdit={onEdit} />
                                    </Grid>
                                </Grid>
                            </ListItem>
                        }
                        <Divider className={classes.Division} />
                    </>
                )
            })}
        </List>
    )
}

Details.propTypes = {
    data: PropTypes.array
}

export default Details;