const inMemoryJWTManager = () => {
    let inMemoryJWT = null
    let authUser = null

    const getToken = () => inMemoryJWT;
    const setToken = (token) => {
        inMemoryJWT = token;
        return true;
    };
    const ereaseToken = () => {
        inMemoryJWT = null;
        return true;
    }

    const getUser = () => authUser;
    const setUser = (user) => {
        authUser = user;
        return true;
    };
    const ereaseUser = () => {
        authUser = null;
        return true;
    }

    return {
        ereaseToken,
        getToken,
        setToken,
        getUser,
        setUser,
        ereaseUser
    }
}

export default inMemoryJWTManager()