import React from 'react'
import PropTypes from 'prop-types'
import usePendingApplication from '../../components/hooks/usePendingApplication'
import Details from './components/details';
import { Box, Button, CircularProgress, Fab, Grid, Typography } from '@material-ui/core';
import { Add as AddIcon } from '@material-ui/icons'
import { useHistory } from 'react-router-dom';
import Modal from '../../components/modal';
import { ESTATUS } from './estatus';
import { toast } from 'react-toastify';
import Select from '../../components/select';
import { fetchMutationApplicationCancel } from '../../components/hooks/useApplicationCancel';
import { useMutation } from 'react-query';

const motivos = [
    { text: "Ya no quiero el préstamo", value: "Ya no quiero el préstamo" },
    { text: "Me equivoqué en el monto solicitado", value: "Me equivoqué en el monto solicitado" }
];
const Estatus = 7;

const PendingApplicationView = ({ userAuth }) => {
    const { data, isLoading, error } = usePendingApplication(userAuth.numEmpleado);
    const navigate = useHistory();
    const [modalState, setModalState] = React.useState(false);
    const [motivo, setMotivo] = React.useState(null);
    const [isSubmitting, setSubmitting] = React.useState(false);
    

    const [mutate] = useMutation(fetchMutationApplicationCancel, {
        onSuccess: () => {
            setSubmitting(false);
            navigate.push("/admin/loan-application");
        },
        onError: (err) => {
            console.log(err.response);
            toast.error(err.response.data.Message, {
                autoClose: 5000
            })
        }
    })
    
    if(error)
        return <p>error</p>
    
    if(isLoading)
        return <CircularProgress />

    const onAccept = () => {
        
        const _estatusDifRegistrado =  data.filter( sol => sol.Estatus !== ESTATUS.REGISTRADO);
        if(_estatusDifRegistrado.length > 0)
        {
            toast.info('Su solicitud se encuentra en proceso por lo tanto no es posible generar otra',{
                autoClose: 5000
            });
        }else {
            const _estatus = data.filter(sol => sol.Estatus === ESTATUS.REGISTRADO);
            if(_estatus.length > 0) {
                setMotivo(null);
                setModalState(true);
            }else
                navigate.push("/admin/loan-application");
            
        }
    }

    const handleCloseModal = () => {
        setModalState(false);
    }

    const onNavigate = (e) => {
        e.preventDefault();
        const toCancel = {
            Id: data?.[0].Id,
            Motivo_Cancel: motivo,
            Estatus
        }
        mutate(toCancel);
    }

    return (
        <>
            <Modal title="Confirmación de Nueva Solicitud" 
                modal={modalState}
                handleCloseModal={handleCloseModal}
                >
                <Grid container>
                    <Grid item xs={12}>
                        <Typography variant="subtitle1" style={{ fontSize: 17 }}>Usted ya cuenta con una solicitud en estatus de registro.</Typography>
                    </Grid>
                    <Grid item xs={12}>
                        <Box pt={2} mb={2} display={"flex"} flexDirection={"column"}>
                            <Typography variant="subtitle2">Desea cancelarla y registrar una nueva?</Typography>
                            
                            <Select
                                variant="standard"
                                margin="normal"
                                fullWidth
                                label="Motivo de Cancelación"
                                id="motivoCancelacion"
                                onChange={e => {
                                    setMotivo(e);
                                }}
                                data={motivos}
                                textField="text"
                                valueField="value"
                                value={motivo}
                                error={!motivo}
                                disabled={isSubmitting}
                                helperText={!motivo ? "El motivo de cancelación es requerido" : ""}
                            />
                            
                            <Box mt={2} display={"flex"} justifyContent="flex-end">
                                <Button variant="contained" disabled={!motivo || isSubmitting} onClick={onNavigate} color="primary" style={{ marginRight: "5px" }}>Aceptar</Button>
                                <Button variant="contained" disabled={isSubmitting} onClick={handleCloseModal} color="default">Cancelar</Button>
                            </Box>
                        </Box>
                    </Grid>
                </Grid>
            </Modal>

            <Grid container>
                <Grid item xs={12} style={{ textAlign: "right" }}>
                    <Fab color="primary" onClick={onAccept} title="Registrar nueva solicitud"> 
                        <AddIcon />
                    </Fab>
                </Grid>
                <Grid item sm={12} style={{ marginTop: 10 }}>
                    <Details data={data} />
                </Grid>
            </Grid>
        </>
    )
}

PendingApplicationView.propTypes = {
    userAuth: PropTypes.object.isRequired
}

export default PendingApplicationView;


