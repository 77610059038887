import React from 'react';
import PropTypes from 'prop-types';
import { makeStyles } from '@material-ui/core/styles';
import { Format } from '../../../utils/format';
import { formatNumber } from 'intl-number-helper';
import { useHistory } from 'react-router-dom';
import { Button, Divider, FormLabel, Grid } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: "16px",
        color: theme.palette.grey[800],
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "14px",
        },
    },
    value: {
        fontSize: "14px",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "12px",
        },
        color: theme.palette.grey[600],
    },
    Division: {
        borderBottom: "1px solid #3F51B5"
    }
}));

const SolicitudHeader = ({ data, children }) => {
    const navigate = useHistory();
    const classes = useStyles();
    const onBack = () => {
        navigate.goBack();
    }

    return (
        <Grid container spacing={1}>
            <Grid item xs={12} style={{ textAlign: "right" }}>
                <Button onClick={onBack} color="secondary" variant="contained"
                    startIcon={<ChevronLeft />}>Regresar</Button>
            </Grid>
            <Grid item xs={9} md={2}>
                <FormLabel className={classes.label}>Folio de Solicitud</FormLabel>
            </Grid>
            <Grid item xs={3} md={10}>
                <FormLabel className={classes.value}>{data.Folio}</FormLabel>
            </Grid>
            <Grid item xs={9} md={2}>
                <FormLabel className={classes.label}>Fecha</FormLabel>
            </Grid>
            <Grid item xs={3} md={10}>
                <FormLabel className={classes.value}>{Format(data.FechaSolicitud)}</FormLabel>
            </Grid>
            <Grid item xs={9} md={2}>
                <FormLabel className={classes.label}>Monto Solicitado</FormLabel>
            </Grid>
            <Grid item xs={3} md={10}>
                <FormLabel className={classes.value}>{formatNumber(data.MontoSolicitado, 'en-US', '$')}</FormLabel>
            </Grid>
            <Grid item xs={9} md={2}>
                <FormLabel className={classes.label}>Observaciones</FormLabel>
            </Grid>
            <Grid item xs={12}>
                <FormLabel className={classes.value}>{data.ObservacionesFidepol}</FormLabel>
            </Grid>
            <Grid item xs={12}>
                <Divider light />
            </Grid>
            <Grid item xs={12}>
                {children}
            </Grid>
        </Grid>
    )
}

SolicitudHeader.propTypes = {
    data: PropTypes.object,
    children: PropTypes.node
}

export default SolicitudHeader
