import React from 'react'
import Button from '@material-ui/core/Button'
import { TextField, CircularProgress } from '@material-ui/core'
import Link from '@material-ui/core/Link'
import Grid from '@material-ui/core/Grid'
import { Formik } from 'formik'
import Notification from '../../../components/notification'
import validationSchema from '../validation'
import PropTypes from 'prop-types'
import { post } from '../../../services/api'
import Img from '../../../static/logo/logo-fidepol-512.png'

const ForgetPassword = (props) => {
    const { classes, onChangeView } = props
    const [externalError, setExternalError] = React.useState(null)

    const onForgethandler = (e) => {
        e.preventDefault()
        onChangeView({
            signin: true,
            forgetpassword: false
        })
    }


    return (
        <div className={classes.paper}>
            <img alt="Logo" src={Img} style={{ width: 250 }} />
            <Formik
                initialValues={{ email: "", numEmpleado: "" }}
                validationSchema={validationSchema}
                onSubmit={async (values, { setSubmitting, resetForm }) => {
                    setExternalError(null)
                    try {
                        const response = await post("/api/passwordrecovery", values)
                        setExternalError(response.data.Message)
                        setTimeout(() => {
                            setExternalError(null)
                            onChangeView({
                                signin: true,
                                forgetpassword: false
                            })
                        },2000)
                        
                    }catch(err) {
                        setExternalError(err.response.data.Message)
                    }
                    setSubmitting(false)
                    
                }}
            >
                {({
                    values,
                    errors,
                    touched,
                    handleChange,
                    handleBlur,
                    handleSubmit,
                    isSubmitting
                }) => (
                        <form className={classes.form} noValidate onSubmit={handleSubmit}>
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                autoFocus
                                label="Número de empleado"
                                name="numEmpleado"
                                id="numEmpleado"
                                onChange={handleChange}
                                value={values.numEmpleado}
                                error={touched.numEmpleado && errors.numEmpleado}
                                helperText={errors.numEmpleado}
                            />
                            <TextField
                                variant="outlined"
                                margin="normal"
                                required
                                fullWidth
                                id="email"
                                name="email"
                                label="Correo electrónico"
                                autoComplete="email"
                                onChange={handleChange}
                                onBlur={handleBlur}
                                value={values.email}
                                error={touched.email && errors.email}
                                helperText={errors.email}
                            />
                            {!isSubmitting &&
                                <Button
                                    type="submit"
                                    fullWidth
                                    variant="contained"
                                    color="primary"
                                    className={classes.submit}
                                >
                                    RECUPERAR CONTRASEÑA
                                    </Button>
                            }
                            <Grid container>
                                <Grid item xs={12} className={classes.spinner}>
                                    {isSubmitting && <CircularProgress />}
                                </Grid>
                            </Grid>
                            {externalError &&
                                <Grid container>
                                    <Grid item xs={12}>
                                        <Notification isOpen={externalError ? true : false} message={externalError} />
                                    </Grid>
                                </Grid>
                            }
                            {!isSubmitting &&
                                <Grid item xs>
                                    <Link onClick={onForgethandler} href="#" variant="body2">Regresar a Ingresar</Link>
                                </Grid>
                            }    
                                
                        </form>
                    )}
            </Formik>
        </div>

    )
}

ForgetPassword.propTypes = {
    classes: PropTypes.object,
    login: PropTypes.func
}

export default ForgetPassword
