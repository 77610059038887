import axios from '../auth/axios-service'
export const fetcher = (...args) => {
    const url = args[0]
    const token = args[1]
    return axios.get(url,{
        headers : {
                    'Authorization': 'Bearer ' + token,
                    'Access-Control-Allow-Origin': '*'
        }
    }).then( response => response.data)
}

export const fetcherSWR = (...args) => {
    const url = args[0]
    return axios.get(url,{
        headers : {
                'Authorization': `Bearer ${window ? localStorage.getItem("token") : null}`,
                'Access-Control-Allow-Origin': '*'
        }
    }).then( response => response.data)
}

export const fetcherReactQuery = (...args) => {
    const url = args[0]
    return axios.get(url,{
        headers : {
                'Authorization': `Bearer ${window ? localStorage.getItem("token") : 0}`,
                'Access-Control-Allow-Origin': '*'
        }
    })
}


export const fetcherBinaryReactQuery = (...args) => {
    const url = args[0]
    return axios.get(url, {
        responseType: 'blob',
        headers: {
            'Authorization': `Bearer ${window ? localStorage.getItem("token") : 0}`,
            'Access-Control-Allow-Origin': '*',
            'Content-Type': 'application/json',
            'Accept': 'application/pdf'
        }
    })
}


export const MutationReactQuery = (...args) => {
    const method = args[0];
    const url = args[1];
    const data = args[2];
    
    return axios({
        method,
        url,
        data,
        headers: {
            'Authorization': `Bearer ${window ? localStorage.getItem("token") : 0}`,
            'Access-Control-Allow-Origin': '*'
        }
    })
}