import * as Yup from 'yup'

const validationSchema = Yup.object().shape({

    IndTransferencia: Yup.boolean()
        .nullable()
        .required(),

    BancoId: Yup.number()
        .when('IndTransferencia', (val, schema) => {
            if (val === true)
                return Yup.number().nullable().required("El banco es requerido");
            else
                return Yup.number();
        }),

    NumeroCuenta: Yup.string()
        .when('IndTransferencia', (val, schema) => {
            if (val === true)
                return Yup.string().max(50, "El número no debe ser mayor a 50 caracteres").nullable()//.required("El número de tarjeta es requerido");
            else
                return Yup.string();
        }),

    CLABE: Yup.string()
        .when('IndTransferencia', (val, schema) => {
            if (val === true)
                return Yup.string().max(18, "La CLABE no debe ser mayor a 18 caracteres").nullable().required("La CLABE es requerida");
            else
                return Yup.string();
        }),

})

export default validationSchema
