import * as Yup from 'yup'

const validationSchema = Yup.object().shape({

    oldpass: Yup.string()
        .required("Contraseña actual es requerida"),

    newpass: Yup.string()
        .min(8, "La nueva contraseña no puede ser menor a 8 caracteres")
        .required("La nueva contraseña es requerido"),

    passwordconfirmation: Yup.string()
        .required("La confirmación de la contraseña es requerido")
        .min(8, "La nueva contraseña no puede ser menor a 8 caracteres")
        .oneOf([Yup.ref('newpass'), null], 'La nueva contraseña y la confirmación no coinciden'),

    email: Yup.string().required(false),
    user: Yup.string().required(false)
})

export default validationSchema
