import React from 'react'
import PropTypes from 'prop-types'
import { PaperCard } from '../../components'
import { CircularProgress, Grid } from '@material-ui/core'
import TableReport from './components/table'
import usePeriodoByDate from '../../components/hooks/usePeriodoByDate'
import usePeriodById from '../../components/hooks/usePeriodById'
import { DateToString } from '../../utils/format'
import { makeStyles } from '@material-ui/core/styles'
const useStyles = makeStyles((theme) => ({
    atentamente: {
        fontWeight: "bold"
    },
    centerRow: {
        textAlign: "center"
    },
    sign: {
        height: "12px"
    },
    hr: {
        border: "none",
        height: "2px",
        backgroundColor:"black"
    },
    panel : {
        marginTop: "1rem"
    },
    p : {
        [theme.breakpoints.down("sm")]: { 
            fontSize: '.8em'
        }
    }
}))

const Index = () => {
    const fecha = new Date();
    const classes = useStyles();
    const { data: period, isLoading: periodLoading } = usePeriodoByDate(DateToString(fecha));
    const { data: period1, isLoading: period1Loading } = usePeriodById(period ? (period.ID - 1) : 0);
    const { data: period2, isLoading: period2Loading, error } = usePeriodById(period ? (period.ID - 2) : 0);
    
    if(error)
        return <p>error</p>
    
    if (periodLoading)
        return <CircularProgress />

    if (period1Loading)
        return <CircularProgress />
    
    if (period2Loading)
        return <CircularProgress />

    return (
        <PaperCard>
            <Grid container>
                <Grid item>
                    <p className={classes.p}>
                        RELATIVO A LAS CANTIDADES QUE POR APORTACION VOLUNTARIA HAN SIDO
                        DESCONTADAS DEL SALARIO MUNICIPAL CATORCENAL DE LOS AFILIADOS DE 
                        LA UNION Y QUE SON AHORRADOS DE ACUERDO CON EL PROGRAMA DE APOYO
                        DENOMINADO PLAN AVPPM PROMOVIDO POR LA UNION.
                    </p>
                </Grid>
                <Grid item xs={12} sm={12}>
                    {period && period1 && period2 &&
                        <TableReport period={period} period1={period1} period2={period2} />
                    }
                </Grid>
                <Grid container sm={12} justify="center">
                    <p className={classes.p}>
                        MEXICALI BAJA CALIFORNIA A {DateToString(fecha, "dd MMMM yyyy")}
                    </p>
                </Grid>
                <Grid container justify="center">
                    <p className={classes.atentamente + " " + classes.p}>
                        ATENTAMENTE
                    </p>
                </Grid>
                <Grid container justify="center">
                    <p>
                        UNION DE AGENTES MUNICIPALES DE MEXICALI ASOCIACION CIVIL
                    </p>
                </Grid>
                <Grid container justify="center" className={classes.panel} >
                    <Grid item xs={12} sm={6} className={classes.sign}>
                        <hr className={classes.hr}/>
                    </Grid>
                    <Grid item sm={12} className={classes.centerRow}>
                        <p className={classes.p}>ALEJANDRO MONREAL NORIEGA</p>
                    </Grid>
                    <Grid item sm={12} className={classes.centerRow}>
                        <p className={classes.p}>PRESIDENTE DEL CONSEJO DIRECTIVO</p>
                    </Grid>
                </Grid>
            </Grid>
        </PaperCard>
    )
}

Index.propTypes = {
    userAuth: PropTypes.object
}

export default Index
