import { fetcherReactQuery } from '../../services/api/fetcher'
import { useQuery } from 'react-query'
const fetchEmployee = async (_, id) => {
    const response = await fetcherReactQuery(`/api/GetInfoUsuario/${id}`)
    return response.data
}
const useEmployee = (user) => {
    return useQuery(["employee", user], fetchEmployee, {
        refetchOnWindowFocus: false,
        staleTime: 300000
    });
}
export default useEmployee;