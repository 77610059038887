import React from 'react'
import Helmet from 'react-helmet'
import Heading from '../components/typography/heading'
import { useAuth } from '../components/auth/useAuth'
import AuthContext from '../components/auth/context'
import SavingsAccoutStatement from '../views/savingsaccountstatemens'

const SavingsAccountStatetment = () => {
    const { UserInfo } = React.useContext(AuthContext)
    const userAuth = useAuth(UserInfo())

    return (
        <>
            <Helmet title={'Estado de Cuenta del Ahorro'} />
            <Heading  text={"Estado de Cuenta del Ahorro"}/>
            {userAuth && userAuth.sub && <SavingsAccoutStatement userAuth={userAuth}/>}
        </>
    )
}

export default SavingsAccountStatetment
