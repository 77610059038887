import React from 'react'
import Helmet from 'react-helmet'
import Heading from '../components/typography/heading'
import { useAuth } from '../components/auth/useAuth'
import AuthContext from '../components/auth/context'
import LoanCalculatorView from '../views/loan-calculator'

const LoanCalculator = (props) => {
    const { UserInfo } = React.useContext(AuthContext);
    const userAuth = useAuth(UserInfo());

    return (
        <>
            <Helmet title={'Simulador'} />
            <Heading  text={"Simulador"}/>
            {userAuth && userAuth.sub && <LoanCalculatorView userAuth={userAuth} />}
        </>
    )
}

export default LoanCalculator
