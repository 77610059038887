import React from 'react';
import Helmet from 'react-helmet';
import { History } from '../views/loan';
import Heading from '../components/typography/heading';
import PaperCard from '../components/paper';
import { Grid } from '@material-ui/core';
import { CircularProgress } from '@material-ui/core';
import AuthContext from '../components/auth/context';
import { useAuth } from '../components/auth/useAuth';
import { fetcher } from '../services/api/fetcher';
import EmailButton from '../components/button/emailButton';
import { toast } from 'react-toastify';

const ApplicationHistory = () => {
    const { UserInfo, getToken } = React.useContext(AuthContext);
    const userAuth = useAuth(UserInfo());
    const [loading, setLoading] = React.useState(false);
    
    const onClickHandler = () => {
        setLoading(true)
        fetcher(`/api/solicitudadmin/sendEmailApplicationHistory/${userAuth.sub}`, getToken()).then(data => {
            setLoading(false)
            toast.success("El historial de prestamos fue enviado exitosamente",{
                autoClose: 3000
            });
        }).catch(err => {
            toast.error("Surgió un error al enviar el correo",{
                autoClose: 3000
            });
            setLoading(false)
        })
    }

    return (
        <>
            <Helmet title={'Historial de Prestamos'} />
            <Heading  text={"Historial de Prestamos"}/>
            <PaperCard>
                <Grid container>
                    <Grid item xs={12} style={{ textAlign: "right"}}>
                        {loading ? <CircularProgress /> : <EmailButton onClick={onClickHandler} />}
                    </Grid>
                    <Grid item xs={12}>
                        {userAuth && userAuth.sub && <History userAuth={userAuth} />}
                    </Grid>
                </Grid>
            </PaperCard>
        </>
    )
}

export default ApplicationHistory;
