// import { fetcherSWR } from '../../services/api/fetcher'
// import useSWR from 'swr'

// const fetch = async (api) => {
//     return await fetcherSWR(api)
// }

// const usePeriodoById = (periodid) => {
//     return useSWR([`/api/periodos/${periodid}`], fetch)
// }

// export default usePeriodoById

import { fetcherReactQuery } from '../../services/api/fetcher';
import { useQuery } from 'react-query';

const fetch = async (api) => {
    const response = await fetcherReactQuery(api)
    return response.data
}

const usePeriodoById = (periodid) => {
    return useQuery([`/api/periodos/${periodid}`], fetch);
}

export default usePeriodoById;




