import { MutationReactQuery } from '../../services/api/fetcher';

const fetchMutationApplicationCancel = async (solicitud) => {
    const response = await MutationReactQuery(
        "post",
        `/api/solicitudadmin/${solicitud.Id}/cancel`,
        solicitud)
    return response.data
}

export { 
    fetchMutationApplicationCancel
}