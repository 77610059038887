import React from 'react'
import useCities from '../hooks/useCities'
import { CircularProgress } from '@material-ui/core'
import Select from '../select'
import PropTypes from 'prop-types'

const CitySelect = (props) => {
    const { stateid, onCityChange, cityid, primitive } = props;
    const [ state, setState] = React.useState(null);
    const { data, isLoading } = useCities(stateid);
    
    React.useEffect(() => {
        setState(cityid);
    }, [cityid])

    const onChange = (value) => {
        setState(value);
        const selected = data.find( (item) => item.Id === +value );
        onCityChange(primitive ? value : selected);
    }
    
    if(isLoading)
        return <CircularProgress />

    return (
        <Select
            id={"City"}
            data={data}
            textField="Nombre"
            valueField="Id"
            label="Municipio"
            placeholder="Seleccionar"
            value={state}
            onChange={onChange}
        />
    )
}

CitySelect.default = {
    primitive: true
}

CitySelect.propTypes = {
    stateid: PropTypes.string,
    cityid: PropTypes.string,
    onCityChange: PropTypes.func,
    primitive: PropTypes.bool
}


export default CitySelect
