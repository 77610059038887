const ESTATUS = {
    REGISTRADO: 0,
    AUTORIZADO_ARRIBA: 9,
    AUTORIZADO_ABAJO: 10,
    INCOMPLETO_DOCUMENTOS: 11,
    INCOMPLETO_BANCARIO: 12,
    INCOMPLETO_FIRMA: 13
};

const estatusStr = (estatus) =>
    {
        switch(estatus) {
            case 0: 
                return "REGISTRADO";
            case 2: 
                return "RECHAZADAS";
            case 9: 
                return "AUTORIZADO ARRIBA";
            case 10: 
                return "AUTORIZADO ABAJO";
            case 11: 
                return "INCOMPLETO DOCUMENTOS";
            case 12: 
                return "INCOMPLETO BANCARIO";
            case 13: 
                return "INCOMPLETO FIRMA";
            default:
                return "Sin Estatus"
        }
    }

export { 
    ESTATUS,
    estatusStr
};