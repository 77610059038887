import React from 'react'
import useNeighborhood from '../hooks/useNeighborhood'
import { CircularProgress } from '@material-ui/core'
import Select from '../select'
import PropTypes from 'prop-types'

const NeighborhoodSelect = (props) => {
    const { cityid, colonyid, onColonyChange, primitive } = props;
    const [ state, setState] = React.useState(null);
    const { data, isLoading } = useNeighborhood(cityid);
    
    React.useEffect(() => {
        setState(colonyid)
    }, [colonyid])

    const onChange = (value) => {
        setState(value)
        const selected = data.find( (item) => item.Id === +value );
        onColonyChange(primitive ? value : selected);
    }
    
    if(isLoading)
        return <CircularProgress />

    return (
        <Select
            id={"Colony"}
            data={data}
            textField="Nombre"
            valueField="Id"
            label="Colonia"
            placeholder="Seleccionar"
            value={state}
            onChange={onChange}
        />
    )
}

NeighborhoodSelect.default = {
    primitive: true
}

NeighborhoodSelect.propTypes = {
    cityid: PropTypes.string,
    colonyid: PropTypes.string,
    onColonyChange: PropTypes.func,
    primitive: PropTypes.bool
}

export default NeighborhoodSelect
