import { fetcherReactQuery } from '../../services/api/fetcher';
import { useQuery } from 'react-query';

const fetch = async (api) => {
    const response = await fetcherReactQuery(api)
    return response.data
}

const useQuarterlyReport = (periodoid) => {
    return useQuery([`/api/reporteInformativoTrimestral/${periodoid}`], fetch);
}

export default useQuarterlyReport;





