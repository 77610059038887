import React from 'react';
import PropTypes from 'prop-types';
import { PaperCard } from '../../components';
import Form from './components/form';
import useEmployee from '../../components/hooks/useEmployee';
import { Button, CircularProgress, Grid } from '@material-ui/core';
import { ChevronLeft } from '@material-ui/icons';
import { useHistory } from 'react-router-dom';


const Index = ({ userAuth }) => {
    const navigate = useHistory();
    const { isLoading, data, error } = useEmployee(userAuth.sub);
    if (error)
        return <p>error</p>

    const newData = {
        step: 0,
        RFC: data?.RFC,
        Direccion: data?.Calle,
        Correo: data?.CorreoElectronico,
        NoExterno: data?.NoExterno,
        CP: data?.CP,
        Telefono: data?.Telefono1,
        idEmpleado: data?.Id,
        NumeroEmpleado: +userAuth.numEmpleado,
        Celular: data?.Telefono2,
        Observaciones: data?.Observaciones,
        MontoPrestamo: data?.MontoPrestamo,
        NumeroSemanas: data?.NumeroSemanas,
        IdEstado: data?.IdEstado,
        IdMunicipio: data?.IdMunicipio,
        idColonia: data?.IdColonia,
        ColoniaSTR: data?.Colonia,
        IndTransferencia: true,
        FechaNacimiento: null,
        EstadoCivilId: null,
        Documents: [],
        Firma: null,
        IndEspecifiqueOtro: false,
        FechaNacimientoConyuge: null,
        NombreConyuge: null,
        MotivosSolicitud: [],
        ContainMotivosSolicitud: null,
        OtroEspecifique: null,
        NivelEducativoId: null,
        CampanaId: null,
        IdEmpresaConvenio: data?.IdEmpresaConvenio,
        DependenciaId: data?.ClaveDependencia ? +data.ClaveDependencia: 0
    };
    console.log("DATA =" , JSON.stringify(newData, null, 2));
    const onBack = (e) => {
        e.preventDefault();
        navigate.push("/admin/pending-application");
    }

    return (
        <PaperCard>
            {
                isLoading ?
                    <CircularProgress /> :
                    (
                        <Grid container>
                            <Grid item xs={12} style={{ textAlign: "right" }}>
                                <Button onClick={onBack} color="secondary" variant="contained"
                                    startIcon={<ChevronLeft />}>Regresar</Button>
                            </Grid>
                            <Grid item xs={12}>
                                <Form data={newData} userAuth={userAuth} />
                            </Grid>
                        </Grid>
                    )
            }
        </PaperCard>
    )
}

Index.propTypes = {
    userAuth: PropTypes.object
}

export default Index
