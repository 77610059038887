import React from 'react'
import PropTypes from 'prop-types'
import { PaperCard } from '../../components'
import { Button, CircularProgress, Grid } from '@material-ui/core'
import Select from '../../components/select'
import { Search as SearchIcon} from '@material-ui/icons'
import { Print as PrintIcon} from '@material-ui/icons'
import useGaugesByPeriodMonth from '../../components/hooks/useGaugesByPeriodMonth'
import Table from './components/table'
import Modal from '../../components/modal'
import { fetcherBinaryReactQuery } from '../../services/api/fetcher'
import { Document, Page } from 'react-pdf';
import { pdfjs } from 'react-pdf';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.min.js`;

const months = [{
    value: 1,
    text: "Enero"
},
{
    value: 2,
    text: "Febrero"
},
{
    value: 3,
    text: "Marzo"
},
{
    value: 4,
    text: "Abril"
},
{
    value: 5,
    text: "Mayo"
},
{
    value: 6,
    text: "Junio"
},
{
    value: 7,
    text: "Julio"
},
{
    value: 8,
    text: "Agosto"
},
{
    value: 9,
    text: "Septiembre"
},
{
    value: 10,
    text: "Octubre"
},
{
    value: 11,
    text: "Noviembre"
},
{
    value: 12,
    text: "Diciembre"
}
]

const years = [{
    value: 2020,
    text: "2020"
},
{
    value: 2021,
    text: "2021"
},
{
    value: 2022,
    text: "2022"
},
{
    value: 2023,
    text: "2023"
},
{
    value: 2024,
    text: "2024"
},
{
    value: 2025,
    text: "2025"
},
{
    value: 2026,
    text: "2026"
},
{
    value: 2027,
    text: "2027"
},
{
    value: 2028,
    text: "2028"
},
{
    value: 2029,
    text: "2029"
},
{
    value: 2030,
    text: "2030"
}
]
const current = new Date()
const Index = (props) => {
    const [filter, setFilter] = React.useState({
        // month: current.getMonth(),
        month: 1,
        periodo: current.getFullYear()
    });
    const [setNumPages] = React.useState(null);
    const [pageNumber] = React.useState(1);
    const [resultSet, setResultSet] = React.useState([])
    const [search, setSearch] = React.useState(null)
    const [setAnchorEl] = React.useState(null)
    const [pdf, setPdf] = React.useState(null)
    const { isLoading, data, error } = useGaugesByPeriodMonth(filter.periodo, filter.month, search);
    const [modal, setModal] = React.useState(false)
    const [downLoading, setDownloading] = React.useState(false)

    React.useEffect(() => {
        if(data && search) {
            setResultSet(data.ResultSet);
        }
    }, [search, data])


    if (error)
        return <p>error</p>

    const periodChangeHandler = (e) => {
        setSearch(null);
        setResultSet([]);
        setFilter( prev => ({
            ...prev,
            periodo: e
        }) )
    }

    const monthChangeHandler = (e) => {
        setSearch(null);
        setResultSet([]);
        setFilter( prev => ({
            ...prev,
            month: e
        }) )
    }
    
    const onSearchClick = (e) => {
        e.preventDefault();
        setSearch(true);
    }

    const handleOpenModal = () => {
        fetchPDF();
        setModal(true);
    }

    const handleCloseModal = () => {
        setAnchorEl(null);
        setModal(false);
    }

    
    const onDocumentLoadSuccess = ({ numPages }) => {
        setNumPages(numPages);
    }

    const fetchPDF = async () => {
        setDownloading(true);
        const response = await fetcherBinaryReactQuery("reportes/exportIndicadoresPorMesPDF/1/2021/1/2021")
        var file = new Blob([response.data], { type: 'application/pdf' });
        var fileURL = URL.createObjectURL(file);
        setPdf(fileURL);
        setModal(true);
        setDownloading(false);
    }

    return (
        <PaperCard>
            <Grid container spacing={2}>
            <Grid item xs={12}>
                    <Select label="Periodo"
                                id="periodo"
                                placeholder="Periodo"
                                textField="text"
                                valueField="value"
                                data={years}
                                value={filter.periodo}
                                onChange={periodChangeHandler}
                            />
                </Grid>
                <Grid item xs={12}>
                    <Select label="Mes"
                                id="month"
                                placeholder="Mes"
                                textField="text"
                                valueField="value"
                                data={months}
                                value={filter.month}
                                onChange={monthChangeHandler}
                            />
                </Grid>
                <Grid item xs={12} sm={12}>
                    <Button
                        startIcon={<SearchIcon />}
                        size="medium"
                        color="primary"
                        variant="contained"
                        onClick={onSearchClick}
                        type="button">Buscar
                    </Button>
                    {" "}
                    <Button
                        startIcon={<PrintIcon />}
                        size="medium"
                        color="default"
                        variant="contained"
                        onClick={handleOpenModal}
                        type="button">Imprimir
                    </Button>
                </Grid>
                {isLoading && <CircularProgress />}
                {!isLoading && data && 
                    <Table data={resultSet} />
                }
            </Grid>
            {downLoading && <CircularProgress />}
            {modal && 
            <Modal fullScreen={true} showFooter={true} modal={modal} handleCloseModal={handleCloseModal} title="Reporte de Indicadores por periodo">
                <div>
                    <Document
                        file={pdf}
                        onLoadSuccess={onDocumentLoadSuccess}
                    >
                        <Page pageNumber={pageNumber} />
                    </Document>
                </div>
            </Modal>}
            
        </PaperCard>
    )
}

Index.propTypes = {
    userAuth: PropTypes.object
}

export default Index


