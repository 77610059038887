import * as Yup from 'yup'
const hideQuestions = process.env.REACT_APP_HIDE_QUESTIONS || false;
const require_clabe_msg = process.env.REACT_APP_REQUIRE_CLABE_MSG;
const max_length_clabe_msg = process.env.REACT_APP_MAX_LENGTH_CLABE_MSG;
const maxLengthClabe = +process.env.REACT_APP_MAX_LENGTH_CLABE ?? 16;
const numSemanasMsgError = process.env.REACT_APP_NUM_SEMANAS_MSG_ERROR ?? "El número de catorcenas es requerido";

const validationSchema = Yup.object().shape({

    RFC: Yup.string()
        .required("El número RFC es requerido"),

    NoExterno: Yup.string()
        .required("El número exterior es requerido"),

    Telefono: Yup.string()
        .nullable(true)
        .max(20, "El número de teléfono no puede ser mayor a 20 caracteres"),

    Observaciones: Yup.string()
        .nullable(true)
        .max(1000, "La observación no puede ser mayor a 1000 caracteres"),

    Celular: Yup.string()
        .nullable(true)
        .max(20, "El número celular no puede ser mayor a 20 caracteres")
        .required("El número de celular es requerido"),

    MontoPrestamo: Yup.number()
        .nullable(true)
        .required("El monto del préstamo es requerido"),
    
    NumeroSemanas: Yup.number()
        .nullable(true)
        .required(numSemanasMsgError),

    NumeroEmpleado: Yup.number()
        .nullable(true)
        .required("El número de empleado es requerido"),

    IndTransferencia: Yup.boolean()
        .nullable()
        .required(),

    BancoId: Yup.number()
        .when('IndTransferencia', (val, schema) => {
            if (val === true)
                return Yup.number().nullable().required("El banco es requerido");
            else
                return Yup.number();
        }),

    NumeroCuenta: Yup.string()
        .when('IndTransferencia', (val, schema) => {
            if (val === true)
                return Yup.string().max(50, "El número no debe ser mayor a 50 caracteres").nullable()//.required("El número de tarjeta es requerido");
            else
                return Yup.string();
        }),

    CLABE: Yup.string()
        .when('IndTransferencia', (value, schema) => {
            if (value === true && maxLengthClabe === 18)
                return Yup.string().min(maxLengthClabe, max_length_clabe_msg).max(maxLengthClabe, max_length_clabe_msg).nullable().required(require_clabe_msg);
            else if (value === true && maxLengthClabe === 16)
                return Yup.string().max(maxLengthClabe, max_length_clabe_msg).nullable().required(require_clabe_msg);
            else
                return Yup.string();
        }),

    idColonia: Yup.number()
        .nullable(true)
        .required("La colonia es requerida"),

    FechaNacimiento: Yup.date()
        .when('step', (val, _) => {
            if (val === 2) {
                if(hideQuestions)
                    return Yup.date()
                    .nullable(true);
                else
                    return Yup.date()
                        .nullable(true)
                        .required("La fecha de nacimiento es requerida");
            }else
                return Yup
                    .date()
                    .nullable(true);
        }),

    NivelEducativoId: Yup.number()
        .when('step', (val, _) => {
            if (val === 2)
                return Yup.number()
                    .nullable(true)
                    .min(1, "El nivel academico es requerido")
                    .required("El nivel academico es requerido");
            else
                return Yup
                    .number()
                    .nullable(true);
    }),
    EstadoCivilId: Yup.number()
        .when('step', (val, _) => {
            if (val === 2) {
                if(hideQuestions)
                    return Yup.number()
                    .nullable(true)
                    .min(1, "El estado civil es requerido")                    
                else
                    return Yup.number()
                    .nullable(true)
                    .min(1, "El estado civil es requerido")
                    .required("El estado civil es requerido");
            } else
                return Yup
                    .number()
                    .nullable(true);
        }),
    NombreConyuge: Yup.string()
        .when(['step', 'EstadoCivilId'], (step, estadoCivil, schema) => {
            if (step === 2 && estadoCivil === 1)
                return Yup.string().max(200, "Nombre de conyugue no puede ser mayor a 100 caracteres")
                    .nullable()
                    .required("El campo nombre del conyugue es requerido");
            else
                return Yup.string()
                    .nullable(true);
        }),
    FechaNacimientoConyuge: Yup.date()
        .when(['step', 'EstadoCivilId'], (step, estadoCivil, schema) => {
            if (step === 2 && estadoCivil === 1)
                return Yup.date()
                    .nullable(true)
                    .required("El campo fecha de nacimiento del conyugue es requerida");
            else
                return Yup.date()
                    .nullable(true);
        }),
    IndEspecifiqueOtro: Yup.boolean()
        .nullable(true),
    OtroEspecifique: Yup.string()
        .when('IndEspecifiqueOtro', (val, schema) => {
            if (val === true)
                return Yup.string().max(500, "Otro no puede ser mayor a 200 caracteres")
                    .nullable()
                    .required("El campo especifique otro es requerido");
            else
                return Yup.string()
                    .nullable(true);
        }),
    ContainMotivosSolicitud: Yup.boolean()
        .when('step', (step, schema) => {
            if (step === 2)
                return Yup.boolean()
                    .nullable(true)
                    .oneOf([true], "El motivo de destino del crédito solicitado es requerido")
                    .required("El motivo de destino del crédito solicitado es requerido");
            else
                return Yup.boolean()
                    .nullable(true)
        }),
    Firma: Yup.string()
        .when('step', (step, schema) => {
            if (step === 3)
                return Yup.string()
                    .nullable(true)
                    .required("El campo firma es requerida");
            else
                return Yup.string()
                    .nullable(true);
        }),
    CampanaId: Yup.number()
        .nullable(true),
    DependenciaId: Yup.number()
    .nullable(false)
    .required("La dependencia es requerida"),

})

export default validationSchema
