import React from 'react'
import Helmet from 'react-helmet'
import Heading from '../components/typography/heading'
import { useAuth } from '../components/auth/useAuth'
import AuthContext from '../components/auth/context'
import QuarterlyReportView from '../views/quarterly-report'

const QuarterlyReport = (props) => {
    const { UserInfo } = React.useContext(AuthContext)
    const userAuth = useAuth(UserInfo())
    return (
        <>
            <Helmet title={'Reporte Trimestral'} />
            <Heading text={"Reporte Trimestral"} />
            {userAuth && userAuth.sub && <QuarterlyReportView userAuth={userAuth} />}
        </>
    )
}

export default QuarterlyReport
