import React from 'react'
import Helmet from 'react-helmet'
import Heading from '../components/typography/heading'
import { useAuth } from '../components/auth/useAuth'
import AuthContext from '../components/auth/context'
import QueryAccountStatusView from '../views/query-accountstatus'

const QueryAccountStatus = (props) => {
    const { UserInfo } = React.useContext(AuthContext)
    const userAuth = useAuth(UserInfo())
    return (
        <>
            <Helmet title={'Consulta del Estado de Cuenta por Empleado'} />
            <Heading text={"Consulta del Estado de Cuenta por Empleado"}/>
            {userAuth && userAuth.sub && <QueryAccountStatusView userAuth={userAuth} />}
        </>
    )
}

export default QueryAccountStatus
