import React from 'react';
import PropTypes from 'prop-types';
import {
    makeStyles,
    CircularProgress,
    Fab,
    Grid,
    TextField,
    Stepper,
    Step,
    StepLabel,
    Box,
    StepContent
} from '@material-ui/core';
import 'date-fns';
import CheckBockList from '../../../components/checkboxlist/checkboxlist';
import DependientesEconomicos from './dependientes_economicos';

import {
    MuiPickersUtilsProvider,
    KeyboardDatePicker,
} from '@material-ui/pickers';
import DateFnsUtils from '@date-io/date-fns';
import { es } from "date-fns/locale";
import { formatNumber } from 'intl-number-helper'

import { useFormik } from 'formik';
import NumericTextBox from "../../../components/numerictextbox";
import { post } from '../../../services/api';
import { Send as SendIcon, ChevronLeft, ChevronRight } from '@material-ui/icons';
import validationSchema from './validation';
import StateSelect from '../../../components/geographic/states-select';
import CitySelect from '../../../components/geographic/city-select';
import ColonySelect from '../../../components/geographic/neighborhood-select';
import { toast } from 'react-toastify';
import { useHistory } from 'react-router-dom';
import Switch from '../../../components/switch';
import useBancos from '../../../components/hooks/useBancos';
import useCampanas from '../../../components/hooks/useCampanas';

import Select from '../../../components/select';
import Documents from './documents';
import SignPad from './signPad';
import Group from "../../../components/group";
import useParentescos from '../../../components/hooks/useParentescos';
import useEstadoCivil from '../../../components/hooks/useEstadoCivil';
import useRequiredDocuments from '../../../components/hooks/useRequiredDocuments';
import { fetchNivelEducativoActive }  from '../../../components/hooks/useNivelEducativo';
import { useQuery } from 'react-query';

const useStyles = makeStyles((theme) => ({
    root: {
        width: '100%',
    },
    button: {
        marginTop: theme.spacing(1),
        marginRight: theme.spacing(1),
    },
    actionsContainer: {
        marginBottom: theme.spacing(2),
    },
    resetContainer: {
        padding: theme.spacing(3),
    },
}));

const uso_prestamo = [
    { text: "Vacaciones", value: 1, name: "Vacaciones" },
    { text: "Automovil", value: 2, name: "Carro" },
    { text: "Escuela", value: 3, name: "Escuela" },
    { text: "Casa / Ampliación / Construcción", value: 4, name: "CasaAmpliacionConstruccion" },
    { text: "Fiesta / Aniversario", value: 5, name: "FiestaAniversario" },
    { text: "Pagar Otro Credito", value: 6, name: "PagarOtroCredito" },
    { text: "Motivos Médicos", value: 8, name: "MotivosMedicos" },
    { text: "Otro", value: 7, name: "OtroMotivo" }
];
const hideQuestions = process.env.REACT_APP_HIDE_QUESTIONS || false;
const enableIndTransferencia = process.env.REACT_APP_ENABLE_IND_TRANSFERENCIA || false;
const clabeText = process.env.REACT_APP_CLABE_TEXT ?? "No. Cuenta / No. Cuenta";
const numSemanas = process.env.REACT_APP_NUM_SEMANAS || "Catorcenas";

const motivosTransform = (motivos) => {
    const data = uso_prestamo.filter(f => motivos.includes(f.value));
    const result = data.reduce((acc, value) => {
        return {
            ...acc,
            [value.name]: true
        }
    }, [])
    return result;
}

const LoanForm = ({ data }) => {
    const classes = useStyles();
    const navigate = useHistory();
    const steps = ["Información Financiera", "Documentos Digitales", "Cuestionario", "Firma Digital"];
    const { data: Bancos } = useBancos();
    const { data: mayores_data, isLoading: mayoresIsLoading } = useParentescos(1);
    const { data: menores_data, isLoading: menoresIsLoading } = useParentescos(2);
    const { data: estadocivil_data, isLoading: estadoCivilIsLoading } = useEstadoCivil();
    const { data: dataDocuments } = useRequiredDocuments();
    const { data: dataNivelesEducativos } = useQuery(['fetch-niveleducativo'], () => fetchNivelEducativoActive(), { staleTime:  30000 });
    const { data: campanas } = useCampanas(data?.IdEmpresaConvenio);

    const onSave = async (payload) => {
        try {
            const response = await post("/api/solicitudadmin/insert", payload);
            toast.success(response.data.Message)
            navigate.push("/admin/pending-application")
            formik.setSubmitting(false);
        } catch (err) {
            toast.error(err?.response?.data?.Message, { autoClose: 5000 })
        }
    }

    const formik = useFormik({
        enableReinitialize: true,
        initialValues: data,
        validationSchema: validationSchema,
        onSubmit: async (values) => {

            const _cuestionarioAdultos = values?.DependientesEconomicos?.Mayores?.Parentescos
                .map(p => {
                    return {
                        ParentescoId: p.Tipo,
                        FechaNacimiento: p.FechaNacimiento
                    }
                });

            const _cuestionarioMenores = values?.DependientesEconomicos?.Menores?.Parentescos
                .map(p => {
                    return {
                        ParentescoId: p.Tipo,
                        FechaNacimiento: p.FechaNacimiento
                    }
                });

            const _toSave = {
                ...values,
                Observaciones: values.Observaciones,
                IndTransferencia: values.IndTransferencia ? 1 : 0,
                Cuestionario: {
                    FechaNacimiento: values?.FechaNacimiento,
                    DependientesEconomicosAdultos: values.DependientesEconomicos?.Mayores?.NumeroPersonas,
                    DependientesEconomicosMenores: values.DependientesEconomicos?.Menores?.NumeroPersonas,
                    NombreConyuge: values?.NombreConyuge,
                    FechaNacimientoConyuge: values?.FechaNacimientoConyuge,
                    OtroEspecifique: values?.OtroEspecifique,
                    EstadoCivilId: values?.EstadoCivilId,
                    CuestionarioDetalle: [],
                    NivelEducativoId: values?.NivelEducativoId,
                    ...motivosTransform(values.MotivosSolicitud)
                }
            }

            if (_cuestionarioAdultos)
                _toSave.Cuestionario.CuestionarioDetalle = [
                    ..._cuestionarioAdultos
                ]

            if (_cuestionarioMenores)
                _toSave.Cuestionario.CuestionarioDetalle = [
                    ..._toSave.Cuestionario.CuestionarioDetalle,
                    ..._cuestionarioMenores
                ]

            _toSave.SolicitudComprobantes = values?.Documents.map(doc => {
                return {
                    DocumentoId: doc?.documentoId,
                    NombreArchivo: doc?.name,
                    Content: doc?.imageDataURL
                }
            });

            delete _toSave.Documents;
            delete _toSave.DependientesEconomicos;
            delete _toSave.FechaNacimiento;
            delete _toSave.FechaNacimientoConyuge;
            delete _toSave.NombreConyuge;
            delete _toSave.ContainMotivosSolicitud;
            delete _toSave.step;
            delete _toSave.OtroEspecifique;
            delete _toSave.NivelEducativoId;
            delete _toSave.IdEmpresaConvenio;
            await onSave(_toSave);
            //formik.setSubmitting(false);
        }
    });

    React.useEffect(() => {
        if (formik) {
            formik.setFieldTouched("MontoPrestamo", true);
        }
    }, [data]);

    return (
        <>
            <div autoComplete="false" noValidate >
                <div className={classes.root}>
                    <Stepper activeStep={formik.values.step} orientation="vertical">
                        {steps.map((item, index) => (
                            <Step key={item} completed={formik.values.step > index}>
                                <StepLabel>{item}</StepLabel>
                                <StepContent>
                                    {formik.values.step === 0 &&
                                        <div>
                                            <Grid container direction={"column"} spacing={1}>
                                                <Grid item>
                                                    <TextField
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        name="RFC"
                                                        label="RFC"
                                                        id="RFC"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.RFC}
                                                        error={formik.touched.RFC && formik.errors.RFC}
                                                        helperText={formik.errors.RFC}
                                                    />
                                                </Grid>
                                                <Grid item sm={12}>
                                                    <StateSelect
                                                        estadoid={formik.values?.IdEstado}
                                                        primitive={false}
                                                        onStateChange={e => {
                                                            formik.setFieldValue("IdEstado", e.Id);
                                                            formik.setFieldValue("IdMunicipio", null);
                                                            formik.setFieldValue("idColonia", null);
                                                        }} />
                                                </Grid>
                                                <Grid item sm={12}>
                                                    <CitySelect
                                                        stateid={formik.values?.IdEstado ? formik.values?.IdEstado : 0}
                                                        cityid={formik.values?.IdMunicipio ? formik.values?.IdMunicipio : 0}
                                                        primitive={false}
                                                        onCityChange={e => {
                                                            formik.setFieldValue("IdMunicipio", e.Id);
                                                            formik.setFieldValue("CiudadSTR", e.Nombre);
                                                            formik.setFieldValue("idColonia", null);
                                                        }} />
                                                </Grid>
                                                <Grid item sm={12}>
                                                    <ColonySelect
                                                        cityid={formik.values.IdMunicipio ? formik.values.IdMunicipio : 0}
                                                        colonyid={formik.values.idColonia ? formik.values.idColonia : 0}
                                                        primitive={false}
                                                        onColonyChange={e => {
                                                            formik.setFieldValue("idColonia", e.Id);
                                                            formik.setFieldValue("ColoniaSTR", e.Nombre);
                                                        }} />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        label="Dirección"
                                                        name="Direccion"
                                                        id="Direccion"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.Direccion}
                                                        error={formik.touched.Direccion && formik.errors.Direccion}
                                                        helperText={formik.errors.Direccion}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        label="No. Exterior"
                                                        name="NoExterno"
                                                        id="NoExterno"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.NoExterno}
                                                        error={formik.touched.NoExterno && formik.errors.NoExterno}
                                                        helperText={formik.errors.NoExterno}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        variant="standard"
                                                        margin="normal"
                                                        fullWidth
                                                        label="Codigo Postal"
                                                        name="CP"
                                                        id="CP"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.CP}
                                                        error={formik.touched.CP && formik.errors.CP}
                                                        helperText={formik.errors.CP}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        variant="standard"
                                                        margin="normal"
                                                        fullWidth
                                                        label="Teléfono"
                                                        name="Telefono"
                                                        id="Telefono"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.Telefono}
                                                        error={formik.touched.Telefono && formik.errors.Telefono}
                                                        helperText={formik.errors.Telefono}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        label="Celular"
                                                        name="Celular"
                                                        id="Celular"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.Celular}
                                                        error={formik.errors.Celular}
                                                        helperText={formik.errors.Celular}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        variant="standard"
                                                        margin="normal"
                                                        fullWidth
                                                        label="Observaciones"
                                                        name="Observaciones"
                                                        id="Observaciones"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.Observaciones}
                                                        error={formik.touched.Observaciones && formik.errors.Observaciones}
                                                        helperText={formik.errors.Observaciones}
                                                    />
                                                </Grid>
                                                { campanas && campanas?.length > 0 &&
                                                    <Grid item>
                                                    <Select
                                                        variant="standard"
                                                        margin="normal"
                                                        fullWidth
                                                        label="Campaña"
                                                        id="CampanaId"
                                                        onChange={e => {
                                                            if(+e > 0)
                                                                formik.setFieldValue("CampanaId", +e);
                                                            else
                                                                formik.setFieldValue("CampanaId", null);
                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        data={campanas || []}
                                                        textField="Nombre"
                                                        valueField="ID"
                                                        placeholder="Campañas"
                                                        value={formik.values.CampanaId}
                                                        error={formik.errors.CampanaId}
                                                        helperText={formik.errors.CampanaId}
                                                    />
                                                    </Grid>
                                                }
                                                <Grid item>
                                                    <TextField
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        label="Monto"
                                                        name="MontoPrestamo"
                                                        id="MontoPrestamo"
                                                        onChange={e => {
                                                            formik.setFieldValue("MontoPrestamo", +e.target.value);

                                                        }}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.MontoPrestamo}
                                                        error={formik.errors.MontoPrestamo}
                                                        helperText={formik.errors.MontoPrestamo}
                                                        InputProps={{
                                                            inputComponent: NumericTextBox,
                                                        }}
                                                    />
                                                </Grid>
                                                <Grid item>
                                                    <TextField
                                                        variant="standard"
                                                        margin="normal"
                                                        required
                                                        fullWidth
                                                        type="number"
                                                        label={numSemanas}
                                                        name="NumeroSemanas"
                                                        id="NumeroSemanas"
                                                        onChange={formik.handleChange}
                                                        onBlur={formik.handleBlur}
                                                        value={formik.values.NumeroSemanas}
                                                        error={formik.errors.NumeroSemanas}
                                                        helperText={formik.errors.NumeroSemanas}
                                                    />
                                                </Grid>
                                                <Grid item xs={12} style={{ marginTop: "1.5rem" }}>
                                                    {JSON.parse(enableIndTransferencia) === false && <Switch checkedA={formik.values?.IndTransferencia} leftText="Cheque" rightText="Transferencia" /> }
                                                    {JSON.parse(enableIndTransferencia) === true && <Switch checkedA={formik.values?.IndTransferencia} onChange={e => {
                                                        formik.setFieldValue("IndTransferencia", e.checkedA);
                                                    }} leftText="Cheque" rightText="Transferencia" /> }
                                                    
                                                    
                                                </Grid>
                                                {formik.values.IndTransferencia &&
                                                    <>
                                                        <Grid item sm={12}>
                                                            <Select
                                                                variant="standard"
                                                                margin="normal"
                                                                fullWidth
                                                                label="Banco"
                                                                id="BancoId"
                                                                onChange={e => {
                                                                    formik.setFieldValue("BancoId", +e);
                                                                }}
                                                                onBlur={formik.handleBlur}
                                                                data={Bancos || []}
                                                                textField="NombreCorto"
                                                                valueField="Id"
                                                                value={formik.values.BancoId}
                                                                error={formik.errors.BancoId}
                                                                helperText={formik.errors.BancoId}
                                                            />
                                                        </Grid>
                                                        <Grid item sm={12}>
                                                            <TextField
                                                                variant="standard"
                                                                margin="normal"
                                                                fullWidth
                                                                label={clabeText}
                                                                name="CLABE"
                                                                id="CLABE"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.CLABE}
                                                                error={formik.errors.CLABE}
                                                                helperText={formik.errors.CLABE}
                                                            />
                                                        </Grid>
                                                    </>
                                                }
                                                <div className={classes.actionsContainer}>
                                                    <Box style={{ marginTop: 20 }}>
                                                        <Fab title="Ir al siguiente paso" disabled={formik.isSubmitting || !formik.isValid} onClick={e => {

                                                            if (!formik.values.MontoPrestamo) {
                                                                formik.setFieldTouched("MontoPrestamo", true);
                                                                return;
                                                            }

                                                            if(!window.confirm(`Esta seguro que el monto solicitado es por ${formatNumber(formik.values.MontoPrestamo, 'en-US', '$' )}?`)) {
                                                                return
                                                            }


                                                            if (formik.isValid) {
                                                                formik.setFieldValue("step", 1);
                                                                e.preventDefault();
                                                            }

                                                        }} color="primary">
                                                            <ChevronRight />
                                                        </Fab>
                                                    </Box>
                                                </div>
                                            </Grid>
                                        </div>
                                    }
                                    {formik.values.step === 1 &&
                                        <Grid item sm={12}>
                                            <Grid item sm={12}>
                                                <Documents
                                                    IndTrasferencia={formik.values.IndTransferencia}
                                                    data={dataDocuments.map(docto => {
                                                        const doc = {
                                                            id: docto.Id,
                                                            ...docto
                                                        }
                                                        return doc;
                                                    }) || []}
                                                    onDoctosChange={doctos => {
                                                        formik.setFieldValue("Documents", doctos);
                                                    }} />
                                            </Grid>
                                            <Box style={{ marginTop: 10 }}>
                                                <Fab title="Ir al paso anterior" disabled={formik.isSubmitting} style={{ marginRight: 10 }}
                                                    onClick={e => formik.setFieldValue("step", 0)} color="default">
                                                    <ChevronLeft />
                                                </Fab>
                                                <Fab title="Ir al siguiente paso" disabled={formik.isSubmitting}
                                                    onClick={e => {
                                                        const canGo = formik
                                                            .values
                                                            .Documents
                                                            .filter(f => !f.done);

                                                        if (formik.values.Documents.length === 0) {
                                                            toast.warning("Faltan documentos para continuar con la solicitud", {
                                                                autoClose: 5000
                                                            })
                                                            return;
                                                        }

                                                        if (canGo.length > 0) {
                                                            toast.warning("Faltan documentos para continuar con la solicitud", {
                                                                autoClose: 5000
                                                            })
                                                            return;
                                                        }

                                                        formik.setFieldValue("step", 2)
                                                    }} color="primary">
                                                    <ChevronRight />
                                                </Fab>
                                            </Box>
                                        </Grid>
                                    }
                                    {formik.values.step === 2 &&
                                        <>
                                            {!hideQuestions &&
                                            <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                                                <Grid item sm={12}>
                                                    <KeyboardDatePicker
                                                        margin="normal"
                                                        id="FechaNacimiento"
                                                        name="FechaNacimiento"
                                                        label="Fecha de Nacimiento"
                                                        format="dd/MM/yyyy"
                                                        onChange={e => {
                                                            formik.setFieldValue("FechaNacimiento", e);
                                                        }}
                                                        KeyboardButtonProps={{
                                                            'aria-label': 'Cambiar fecha',
                                                        }}
                                                        value={formik.values.FechaNacimiento}
                                                        helperText={formik.errors.FechaNacimiento}
                                                        error={formik.errors.FechaNacimiento}
                                                    />
                                                </Grid>
                                            </MuiPickersUtilsProvider>
                                            }
                                            {!hideQuestions &&
                                            <Grid item sm={12}>
                                                {estadoCivilIsLoading && <CircularProgress />}
                                                {!estadoCivilIsLoading && <Select
                                                    variant="standard"
                                                    margin="normal"
                                                    fullWidth
                                                    label="Estado Civil"
                                                    id="EstadoCivilId"
                                                    onChange={e => {
                                                        formik.setFieldValue("EstadoCivilId", +e);
                                                        if (+e !== 1) {
                                                            formik.setFieldValue("NombreConyuge", null);
                                                            formik.setFieldValue("FechaNacimientoConyuge", null);
                                                        }
                                                    }}
                                                    onBlur={formik.handleBlur}
                                                    data={estadocivil_data || []}
                                                    textField="Descripcion"
                                                    valueField="Id"
                                                    placeholder="Estado Civil"
                                                    value={formik.values.EstadoCivilId}
                                                    error={formik.errors.EstadoCivilId}
                                                    helperText={formik.errors.EstadoCivilId}
                                                />
                                                }
                                            </Grid>
                                            }
                                            {formik.values.EstadoCivilId === 1 &&
                                                <>
                                                    <Grid item sm={12}>
                                                        <TextField
                                                            variant="standard"
                                                            margin="normal"
                                                            fullWidth
                                                            label="Nombre Cónyuge"
                                                            name="NombreConyuge"
                                                            id="NombreConyuge"
                                                            onChange={formik.handleChange}
                                                            onBlur={formik.handleBlur}
                                                            value={formik.values.NombreConyuge}
                                                            error={formik.errors.NombreConyuge}
                                                            helperText={formik.errors.NombreConyuge}
                                                        />
                                                    </Grid>
                                                    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={es}>
                                                        <Grid item sm={12}>
                                                            <KeyboardDatePicker
                                                                margin="normal"
                                                                id="FechaNacimientoConyuge"
                                                                name="FechaNacimientoConyuge"
                                                                label="Fecha de Nacimiento"
                                                                format="dd/MM/yyyy"
                                                                onChange={e => {
                                                                    formik.setFieldValue("FechaNacimientoConyuge", e);
                                                                }}
                                                                KeyboardButtonProps={{
                                                                    'aria-label': 'Cambiar fecha',
                                                                }}
                                                                onBlur={formik.onBlur}
                                                                value={formik.values.FechaNacimientoConyuge}
                                                                helperText={formik.errors.FechaNacimientoConyuge}
                                                                error={formik.errors.FechaNacimientoConyuge}
                                                            />
                                                        </Grid>
                                                    </MuiPickersUtilsProvider>
                                                </>
                                            }
                                            {!hideQuestions &&
                                            <Grid item sm={12} style={{ marginTop: 15 }}>
                                                <Group title="Dependientes Económicos">
                                                    <Grid item={12}>
                                                        {mayoresIsLoading && <CircularProgress />}
                                                        {!mayoresIsLoading && <DependientesEconomicos
                                                            change={data => {
                                                                formik.setFieldValue("DependientesEconomicos.Mayores", data);
                                                            }}
                                                            headerText="Adultos"
                                                            source={mayores_data || []}
                                                            textField="Descripcion"
                                                            valueField="Id"
                                                        />
                                                        }
                                                    </Grid>
                                                    <Grid item={12} style={{ paddingTop: 15 }}>
                                                        {menoresIsLoading && <CircularProgress />}
                                                        {!menoresIsLoading &&
                                                            <DependientesEconomicos
                                                                change={data => {
                                                                    formik.setFieldValue("DependientesEconomicos.Menores", data);
                                                                }}
                                                                textField="Descripcion"
                                                                valueField="Id"
                                                                headerText="Menores" source={menores_data || []} />
                                                        }
                                                    </Grid>
                                                </Group>
                                            </Grid>
                                            }
                                            <Grid item sm={12} style={{ marginTop: 15 }}>
                                            <Select
                                                variant="standard"
                                                margin="normal"
                                                fullWidth
                                                label="Nivel Academico"
                                                id="NivelEducativoId"
                                                onChange={e => {
                                                    formik.setFieldValue("NivelEducativoId", +e);
                                                }}
                                                onBlur={formik.handleBlur}
                                                data={dataNivelesEducativos || []}
                                                textField="Descripcion"
                                                valueField="Id"
                                                value={formik.values.NivelEducativoId}
                                                error={formik.errors.NivelEducativoId}
                                                helperText={formik.errors.NivelEducativoId}
                                            />
                                            </Grid>
                                            <Grid item sm={12} style={{ marginTop: 15 }}>
                                                <Group error={formik.values.MotivosSolicitud.length === 0} title="Indique para cual de las opciones esta destinado el crédito solicitado?">
                                                    <Grid item sm={12}>
                                                        <CheckBockList onChange={checked => {
                                                            formik.setFieldValue("IndEspecifiqueOtro", checked.includes(7));
                                                            formik.setFieldValue("MotivosSolicitud", checked);
                                                            formik.setFieldValue("ContainMotivosSolicitud", checked.length > 0);
                                                        }}
                                                            checkedItems={formik.values?.MotivosSolicitud}
                                                            items={uso_prestamo} />
                                                    </Grid>
                                                    {formik.values.IndEspecifiqueOtro &&
                                                        <Grid item sm={12}>
                                                            <TextField
                                                                variant="standard"
                                                                margin="normal"
                                                                fullWidth
                                                                multiline
                                                                row={2}
                                                                label="Especifique"
                                                                name="OtroEspecifique"
                                                                id="OtroEspecifique"
                                                                onChange={formik.handleChange}
                                                                onBlur={formik.handleBlur}
                                                                value={formik.values.OtroEspecifique}
                                                                error={formik.errors.OtroEspecifique}
                                                                helperText={formik.errors.OtroEspecifique}
                                                            />
                                                        </Grid>
                                                    }
                                                </Group>
                                            </Grid>
                                            <Grid item sm={12}>
                                                <Box style={{ marginTop: 10 }}>
                                                    <Fab disabled={formik.isSubmitting} style={{ marginRight: 10 }}
                                                        title="Ir a paso anterior"
                                                        onClick={e => formik.setFieldValue("step", 1)} color="default">
                                                        <ChevronLeft />
                                                    </Fab>
                                                    <Fab disabled={formik.isSubmitting || !formik.isValid} title="Ir a siguiente paso"
                                                        onClick={e => {
                                                            const canGo = formik
                                                                .values
                                                                .MotivosSolicitud;

                                                            if (!hideQuestions && !formik.values.EstadoCivilId) {
                                                                formik.setFieldTouched("EstadoCivilId", true);
                                                                return;
                                                            }

                                                            if (!hideQuestions && !formik.values.FechaNacimiento) {
                                                                formik.setFieldTouched("FechaNacimiento", true);
                                                                return;
                                                            }

                                                            formik.setFieldValue("ContainMotivosSolicitud", true);

                                                            if (canGo.length <= 0) {
                                                                toast.warning("Para continuar es requerido que se seleccione por lo menos una opción de destino de crédito", {
                                                                    autoClose: 5000
                                                                })
                                                                return;
                                                            }

                                                            if (formik.values?.DependientesEconomicos?.Mayores) {
                                                                if (formik.values?.DependientesEconomicos?.Mayores?.NumeroPersonas > 0) {
                                                                    const mayoresValid = formik.values?.DependientesEconomicos?.Mayores.Parentescos.filter(p => {
                                                                        const tipoIsValid = !p.Tipo
                                                                        const fechaIsValid = !p.FechaNacimiento
                                                                        return tipoIsValid && fechaIsValid
                                                                    })

                                                                    if (mayoresValid.length > 0) {
                                                                        toast.warning("El número de mayores y la información detallada no coinciden", {
                                                                            autoClose: 5000
                                                                        })
                                                                        return;
                                                                    }
                                                                }
                                                            }

                                                            if (formik.values?.DependientesEconomicos?.Menores) {
                                                                if (formik.values?.DependientesEconomicos?.Menores.NumeroPersonas > 0) {
                                                                    const menoresValid = formik.values?.DependientesEconomicos?.Menores.Parentescos.filter(p => {
                                                                        const tipoIsValid = !p.Tipo
                                                                        const fechaIsValid = !p.FechaNacimiento
                                                                        return tipoIsValid && fechaIsValid
                                                                    })

                                                                    if (menoresValid.length > 0) {
                                                                        toast.warning("El número de menores y la información detallada no coinciden", {
                                                                            autoClose: 5000
                                                                        })
                                                                        return;
                                                                    }
                                                                }
                                                            }

                                                            if (formik.isValid) {
                                                                formik.setFieldValue("step", 3)
                                                                e.preventDefault();
                                                            }


                                                        }} color="primary">
                                                        <ChevronRight />
                                                    </Fab>
                                                </Box>
                                            </Grid>
                                        </>
                                    }
                                    {formik.values.step === 3 &&
                                        <>
                                            <Grid item sm={12}>
                                                <SignPad error={!formik.isValid} disabled={formik.isSubmitting} onSignChanged={sign => {
                                                    formik.setFieldValue("Firma", sign);
                                                }}
                                                    onClear={clear => {
                                                        formik.setFieldValue("Firma", clear);
                                                    }}
                                                />
                                            </Grid>
                                            <Grid item sm={12}>
                                                <Box style={{ marginTop: 10 }}>
                                                    <Fab disabled={formik.isSubmitting} style={{ marginRight: 10 }}
                                                        onClick={e => formik.setFieldValue("step", 2)} color="default">
                                                        <ChevronLeft />
                                                    </Fab>
                                                </Box>
                                            </Grid>
                                            <div>
                                                <Grid item style={{ textAlign: "right" }} sm={12}>
                                                    {formik.isSubmitting ? <CircularProgress /> :
                                                        <Fab type="submit"
                                                            size="large"
                                                            style={{ height: 70, width: 70 }}
                                                            color="primary"
                                                            title="Grabar solicitud"
                                                            disabled={!formik.isValid}
                                                            onClick={e => {
                                                                formik.setSubmitting(true);
                                                                formik.handleSubmit();
                                                            }}
                                                        >
                                                            <SendIcon size="30px" />
                                                        </Fab>
                                                    }
                                                </Grid>
                                            </div>
                                        </>
                                    }
                                </StepContent>
                            </Step>
                        ))}
                    </Stepper>
                </div>
            </div>
        </>
    )
}

LoanForm.propTypes = {
    data: PropTypes.object,
    userAuth: PropTypes.object
}

export default LoanForm

