import React from 'react'
import { List, ListItem, Divider, Grid, CircularProgress, FormLabel  } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Format } from '../../utils/format'
import { formatNumber } from 'intl-number-helper'
import PropTypes from 'prop-types'
import useLoanHistory from '../../components/hooks/useLoanHistory'

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: "16px",
        color: theme.palette.grey[800],
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "14px",
        },
    },
    value: {
        fontSize: "14px",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "12px",
        },
        color: theme.palette.grey[600],
    },
    Division: {
        borderBottom: "1px solid #3F51B5"
    }
}));

const SavingActive = (props) => {
    const { saving } = props
    const classes = useStyles()
    return (
        <>
            <Grid container>
                <Grid item xs={9} md={2}>
                    <FormLabel className={classes.label}>Saldo</FormLabel>
                </Grid>
                <Grid item xs={3} md={10}>
                    <FormLabel className={classes.value}>{formatNumber(saving.SaldoPrestamo, 'en-US', '$' )}</FormLabel>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={9} md={2}>
                    <FormLabel className={classes.label}>Plazo</FormLabel>
                </Grid>
                <Grid item xs={3} md={10}>
                    <FormLabel className={classes.value}>{saving.Plazo}</FormLabel>
                </Grid>
            </Grid>
            <Grid container>
                <Grid item xs={9} md={2}>
                    <FormLabel className={classes.label}>Abono</FormLabel>
                </Grid>
                <Grid item xs={3} md={10}>
                    <FormLabel className={classes.value}>{formatNumber(saving.AbonoFijo, 'en-US', '$' )}</FormLabel>
                </Grid>
            </Grid>
        </>
    )
}

const Savings = (props) => {
    const { data } = props
    const classes = useStyles()
    return (
        <List>
            {data && data.map(item => {
                return (
                    <>
                    <ListItem>
                        <Grid container xs={12}>
                            <Grid container>
                                <Grid item xs={9} md={2}>
                                    <FormLabel className={classes.label}>Fecha</FormLabel>
                                </Grid>
                                <Grid item xs={3} md={10}>
                                    <FormLabel className={classes.value}>{Format(item.FechaRegistro)}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={9} md={2}>
                                    <FormLabel className={classes.label}>Núm prestamo</FormLabel>
                                </Grid>
                                <Grid item xs={3} md={10}>
                                    <FormLabel className={classes.value}>{item.Folio}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={9} md={2}>
                                    <FormLabel className={classes.label}>Monto</FormLabel>
                                </Grid>
                                <Grid item xs={3} md={10}>
                                    <FormLabel className={classes.value}>{formatNumber(item.MontoOperacionDec, 'en-US', '$' )}</FormLabel>
                                </Grid>
                            </Grid>
                                {!item.IndCerrado ? <SavingActive saving={item} /> : <></>}
                            <Grid container>
                                <Grid item xs={9} md={2}>
                                    <FormLabel className={classes.label}>Estatus</FormLabel>
                                </Grid>
                                <Grid item xs={3} md={10}>
                                    <FormLabel className={classes.value}>  {item.IndCerrado ? "Pagado" : "Activo"}</FormLabel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Divider className={classes.Division}/>
                    </>
                )
            })}
        </List>
    )
}

const History = (props) => {
    const  { userAuth } = props;
    const { data, isLoading, error } = useLoanHistory(userAuth.sub);
    if(error)
        return <p>error</p>
    
    if(isLoading)
        return <CircularProgress />
    
    return <Savings data={data} />
}

History.propTypes = {
    userAuth : PropTypes.object
}

export default History;

