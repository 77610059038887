import React from 'react'
import { List, ListItem, Divider, Grid, CircularProgress, FormLabel  } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import { Format } from '../../utils/format'
import { formatNumber } from 'intl-number-helper'
import PropTypes from 'prop-types'
import useApplicationHistory from '../../components/hooks/useApplicationHistory'
import { estatusStr } from '../pending-application-view/estatus';

const useStyles = makeStyles((theme) => ({
    label: {
        fontSize: "16px",
        color: theme.palette.grey[800],
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "14px",
        },
    },
    value: {
        fontSize: "14px",
        ['@media (max-width:768px)']: { // eslint-disable-line no-useless-computed-key
            fontSize: "12px",
        },
        color: theme.palette.grey[600],
    },
    Division: {
        borderBottom: "1px solid #3F51B5"
    }
}));

const Savings = (props) => {
    const { data } = props;
    const classes = useStyles();
    return (
        <List>
            {data && data.map(item => {
                return (
                    <>
                    <ListItem>
                        <Grid container xs={12}>
                            <Grid container>
                                <Grid item xs={9} md={2}>
                                    <FormLabel className={classes.label}>Fecha</FormLabel>
                                </Grid>
                                <Grid item xs={3} md={10}>
                                    <FormLabel className={classes.value}>{Format(item.FechaSolicitud)}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={9} md={2}>
                                    <FormLabel className={classes.label}>Núm prestamo</FormLabel>
                                </Grid>
                                <Grid item xs={3} md={10}>
                                    <FormLabel className={classes.value}>{item.Folio}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={9} md={2}>
                                    <FormLabel className={classes.label}>Monto</FormLabel>
                                </Grid>
                                <Grid item xs={3} md={10}>
                                    <FormLabel className={classes.value}>{formatNumber(item.MontoSolicitado, 'en-US', '$' )}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={9} md={2}>
                                    <FormLabel className={classes.label}>Catorcenas Solicitadas</FormLabel>
                                </Grid>
                                <Grid item xs={3} md={10}>
                                    <FormLabel className={classes.value}>{formatNumber(item.SemanasSolicitadas, 'en-US', '' )}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={9} md={2}>
                                    <FormLabel className={classes.label}>Observaciones</FormLabel>
                                </Grid>
                                <Grid item xs={3} md={10}>
                                    <FormLabel className={classes.value}>{item.Observaciones}</FormLabel>
                                </Grid>
                            </Grid>
                            <Grid container>
                                <Grid item xs={9} md={2}>
                                    <FormLabel className={classes.label}>Estatus</FormLabel>
                                </Grid>
                                <Grid item xs={3} md={10}>
                                    <FormLabel className={classes.value}>{estatusStr(item.Estatus)}</FormLabel>
                                </Grid>
                            </Grid>
                        </Grid>
                    </ListItem>
                    <Divider className={classes.Division}/>
                    </>
                )
            })}
        </List>
    )
}

const Application = (props) => {
    const { userAuth } = props
    const { data, isLoading, error } = useApplicationHistory(userAuth.sub);
    
    if(error)
        return <p>error</p>
    
    if(isLoading)
        return <CircularProgress />
    
    return <Savings data={data} />
}

Application.propTypes = {
    userAuth : PropTypes.object
}

export default Application

