import React from 'react';
import { useParams } from 'react-router-dom';
import Helmet from 'react-helmet';
import Heading from '../components/typography/heading';
import { useAuth } from '../components/auth/useAuth';
import AuthContext from '../components/auth/context';
import { PaperCard } from '../components';
import { CircularProgress } from '@material-ui/core';
import Documents from '../views/pending-application-view/components/documents';
import { useApplicationDocuments } from "../components/hooks/useApplicationDocuments";
import SolicitudHeader from '../views/pending-application-view/components/solicitud-header';


const ApplicationDocuments = (props) => {
    const params = useParams();
    const [id, setId] = React.useState(null);
    const { UserInfo } = React.useContext(AuthContext);
    const userAuth = useAuth(UserInfo());
    const { data, isLoading } = useApplicationDocuments(id || 0);
    
    React.useEffect(() => {
        if(params)
            setId(+params.id);    
    }, [params]);
    
    return (
        <>
            <Helmet title={'Corrección de Documentos'} />
            <Heading text={"Corrección de Documentos"} />
                {userAuth && userAuth.sub &&  
                <PaperCard>
                    {
                        isLoading ?
                            <CircularProgress /> :
                            (
                                <SolicitudHeader data={data}>
                                        <Documents showCamera={false} solicitud={data} />
                                </SolicitudHeader>
                                
                            )
                    }
                </PaperCard>
            }
        </>
    )
}

export default ApplicationDocuments;
