import React from 'react'
import PropTypes from 'prop-types'
import ForgetPasswordRfc from './components/forget-password-rfc';
import ForgetPassword from './components/forget-password';

const Index = (props) => {
    const { classes, onChangeView } = props
    const oauth_type = process.env.REACT_APP_OAUTH;
    return (oauth_type === "RFC" ? <ForgetPasswordRfc classes={classes} onChangeView={onChangeView}/> : <ForgetPassword classes={props.classes} onChangeView={onChangeView} />)
}

Index.propTypes = {
    classes: PropTypes.object,
    login: PropTypes.func
}

export default Index
