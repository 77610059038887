import React from 'react'
import PropTypes from 'prop-types'
import { PaperCard } from '../../components'
import { CircularProgress, FormLabel, Grid, TextField } from '@material-ui/core'
import { makeStyles } from '@material-ui/core/styles'
import AccountStatusTable from '../accountstatus/table'
import useFinancialProductType from '../../components/hooks/useFinancialProductType'
import useEmployeeSearch from '../../components/hooks/useEmployeeSearch'
import Select from '../../components/select'
import Search from '../../components/search/search'
import { formatNumber } from 'intl-number-helper'
import EmailButton from '../../components/button/emailButton'
import { fetcherSWR } from '../../services/api/fetcher'

const useStyles = makeStyles((theme) => ({
    atentamente: {
        fontWeight: "bold"
    },
    centerRow: {
        textAlign: "center"
    },
    sign: {
        height: "12px"
    },
    hr: {
        border: "none",
        height: "2px",
        backgroundColor: "black"
    },
    panel: {
        marginTop: "1rem"
    },
    p: {
        [theme.breakpoints.down("sm")]: {
            fontSize: '.8em'
        }
    },
    label: {
        fontSize: "16px",
        color: theme.palette.grey[600],
    },
    value: {
        fontSize: "16px",
        color: theme.palette.grey[800],
    },
    saldoPanel : {
        marginBottom: "1rem",
        marginTop: "1rem"
    }
}))

const getFinalBalance = (accountStatus) => {
    return accountStatus ? accountStatus.length > 0 ? accountStatus[accountStatus.length - 1].Saldo : 0 : 0
}

const Index = (props) => {
    const classes = useStyles()
    const [tipoProducto, setTipoProducto] = React.useState(null)
    const [employeeNum, setEmployeeNum] = React.useState("")
    const [entity, setEntity] = React.useState(null)
    const [saldo, setSaldo] = React.useState(0)
    const [sendEmail, setSendEmail] = React.useState(false)

    const { isLoading, data: employee, error } = useEmployeeSearch(employeeNum)
    const { data: tipoprestamos, error: tipoprestamoError } = useFinancialProductType(employee ? employee.EmpresaConvenioId : 0)

    React.useEffect(() => {
        setEntity(employee)
    }, [employee, employeeNum])

    const numEmployeeHandler = (numEmp) => {
        setEmployeeNum(numEmp)
    }

    const saldoHandler = (accountStatus) => {
        setSaldo(getFinalBalance(accountStatus))
    }

    const onChangeHandler = (financialproducttypeid) => {
        setTipoProducto(financialproducttypeid)
    }

    const onClickHandler = () => {
        setSendEmail(true)
        fetcherSWR(`/api/SendEmailEstadoCuenta/${employee.EmpresaConvenioId}/${employee.Id}/${tipoProducto}`).then(data => {
            setSendEmail(false)
        })
    }

    if (error)
        return <p>error</p>

    if (tipoprestamoError)
        return <p>error</p>

    return (
        <PaperCard>
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <Search onSearchChange={numEmployeeHandler} />
                </Grid>
                {entity &&
                    <>
                        <Grid item xs={12}>
                            <TextField value={entity.Busqueda}
                                label="Nombre de Empleado"
                                fullWidth
                                disabled
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <Select label="Tipo de Prestamo"
                                id="tipoprestamoid"
                                textField="Nombre"
                                valueField="IdProductoFinanciero"
                                data={tipoprestamos}
                                value={null}
                                onChange={onChangeHandler}
                            />
                        </Grid>
                    </>
                }
                {isLoading && <Grid item xs={12}><CircularProgress /> </Grid>}
                {entity &&
                    <Grid container justify={"flex-end"} className={classes.saldoPanel}>
                        <Grid item xs={2} sm={2} md={1} lg={1}>
                            <FormLabel className={classes.label}>Saldo</FormLabel>
                        </Grid>
                        <Grid item xs={4} sm={2} md={2} lg={1}>
                            <FormLabel className={classes.value}>{formatNumber(saldo, "en-US", "$")}</FormLabel>
                        </Grid>
                    </Grid>
                }
                <Grid container justify={"flex-end"}>
                    {entity ? tipoProducto ? !sendEmail ? <EmailButton onClick={onClickHandler} /> : <CircularProgress /> : null : null}
                </Grid>
                {!isLoading && employee &&
                    <Grid item xs={12} sm={12}>
                        <AccountStatusTable
                            userid={employee.Id}
                            empresaconvenioid={employee.EmpresaConvenioId}
                            financialProductId={tipoProducto}
                            onSaldoChange={saldoHandler} />
                    </Grid>
                }
            </Grid>
        </PaperCard>
    )
}

Index.propTypes = {
    userAuth: PropTypes.object
}

export default Index


