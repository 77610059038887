import React, { useCallback } from 'react'
import PropTypes from 'prop-types'
import clsx from 'clsx'
import { makeStyles } from '@material-ui/core/styles'
import CssBaseline from '@material-ui/core/CssBaseline'
import {
  Hidden, Drawer, AppBar, Toolbar, List, ListItem, ListItemIcon,
  ListItemText, Typography, Divider, IconButton, Container, Grid, Menu, MenuItem
} from '@material-ui/core'
import MenuIcon from '@material-ui/icons/Menu'
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft'
import SettingsIcon from '@material-ui/icons/Settings'
import AuthContext from '../components/auth/context'
import { useAuth } from '../components/auth/useAuth'
import Model from '../components/modal'
import ChangePassword from '../views/changepassword'
import menus from '../data/menus'
import { Link, useHistory, Switch, Route, Redirect } from "react-router-dom"
import SavingInfo from '../pages/saving-info'
import Profile from '../pages/profile'
import AccountStatus from '../pages/account-status'
import LoanHistory from '../pages/loan-history'
import ApplicationHistory from '../pages/application-history'
import LoanApplication from '../pages/loan-application'
import LoanCalculator from '../pages/loan-calculator'
import SavingsAccountStatetment from '../pages/savings-account-statetment'
import QuarterlyReport from '../pages/quarterly-report'

import QuarterlyAccountStatus from '../pages/query-accountstatus'
import QueryAccountSavings from '../pages/query-account-savings'
import QueryGauges from '../pages/query-gauges'
import PendingApplication from '../pages/pending-application';
import ApplicationDocuments from '../pages/application-documents';
import ApplicationSign from '../pages/application-sign';
import ApplicationBankInfo from '../pages/application-bank-info'


const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    })
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.down('xs')]: {
      width: theme.spacing(0),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    height: '100vh',
    overflow: 'auto'
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  fixedHeight: {
    minHeight: 250,
  },
  listItemText: {
    fontSize: '14px',
    whiteSpace: "pre-line",
  },
  listItemTextClose: {
    display: "none",
  }
}))

const CustomLink = (props) => {
  const { to } = props
  return (<Link to={to} {...props} />)
}

const filterMenusByRole = (roles) => {
  return menus.filter(f => f.roles.includes(roles))
}

const SwitchRoutes = () => (
  <Switch>
    <Redirect
      exact
      from="/"
      to="/admin/profile"
    />
    <Route exact path="/admin/saving-info">
      <SavingInfo />
    </Route>
    <Route exact path="/admin/profile">
      <Profile />
    </Route>
    <Route exact path="/admin/pending-application">
      <PendingApplication />
    </Route>
    <Route exact path="/admin/account-status">
      <AccountStatus />
    </Route>
    <Route exact path="/admin/loan-history">
      <LoanHistory />
    </Route>
    <Route exact path="/admin/application-history">
      <ApplicationHistory />
    </Route>
    <Route exact path="/admin/loan-application">
      <LoanApplication />
    </Route>
    <Route exact path="/admin/loan-calculator">
      <LoanCalculator />
    </Route>
    <Route exact path="/admin/savings-account-statetment">
      <SavingsAccountStatetment />
    </Route>
    <Route exact path="/admin/quarterly-report">
      <QuarterlyReport />
    </Route>
    <Route exact path="/admin/query-accountstatus">
      <QuarterlyAccountStatus />
    </Route>
    <Route exact path="/admin/query-account-savings">
      <QueryAccountSavings />
    </Route>
    <Route exact path="/admin/query-gauges">
      <QueryGauges />
    </Route>
    <Route exact path="/admin/application-documents/:id">
      <ApplicationDocuments />
    </Route>
    <Route exact path="/admin/application-sign/:id">
      <ApplicationSign />
    </Route>
    <Route exact path="/admin/application-bank-info/:id">
      <ApplicationBankInfo />
    </Route>

  </Switch>
)

const Layout = () => {
  const classes = useStyles()
  const [anchorEl, setAnchorEl] = React.useState(null)
  const [open, setOpen] = React.useState(true)
  const [modal, setModal] = React.useState(false)
  const { isLogedIn, logout, UserInfo } = React.useContext(AuthContext)
  const userAuth = useAuth(UserInfo())
  const history = useHistory()
  const [windowWidth, setWindowWidth] = React.useState(window.innerWidth);
  
  React.useEffect(() => {
    
    const handleResize = () => {
      setWindowWidth(window.innerWidth);
    };
    window.addEventListener('resize', handleResize);
    if(windowWidth <= 768)
      setOpen(false);
    else 
      setOpen(true);

    return () => {
      window.removeEventListener('resize', handleResize);
    };
    
  }, [windowWidth]);


  const handleDrawerOpen = useCallback(() => {
    setOpen(true)
  }, []);

  const handleDrawerClose = useCallback(() => {
    setOpen(false);
  }, []);

  const handleClick = useCallback((event) => {
    setAnchorEl(event.currentTarget);
  }, []);

  const handleClose = useCallback(() => {
    setAnchorEl(null)
  }, []);

  const handleOpenModal = useCallback(
    () => {
      setModal(true)
    }, [])

  const handleCloseModal = () => {
    setAnchorEl(null)
    setModal(false)
  }

  const handleLogout = (e) => {
    e.preventDefault()
    logout()
    history.push("/sign-in")
  }

  const drawer = (
    <>
      <div className={classes.toolbarIcon}>
        <IconButton onClick={handleDrawerClose}>
          <ChevronLeftIcon />
        </IconButton>
      </div>
      <Divider />
      <List>
        {userAuth ? filterMenusByRole(userAuth.roles).map(({ text, Icon, to }, index) => (
          <ListItem button key={text} component={CustomLink} to={to}>
            <ListItemIcon >
              <Icon title={text} />
            </ListItemIcon>
            <ListItemText title={text} disableTypography className={clsx(classes.listItemText, !open && classes.listItemTextClose)} primary={text} />
          </ListItem>
        )) : null}
      </List>
    </>
  )

  React.useEffect(() => {
    if (!isLogedIn())
      history.push("/sign-in")
  }, [history, userAuth, isLogedIn])

  if (!isLogedIn())
    return <div></div>

  if (!userAuth)
    return <div></div>

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Fidepol
          </Typography>
          <IconButton color="inherit" aria-controls="simple-menu" aria-haspopup="true" onClick={handleClick}>
            <SettingsIcon />
          </IconButton>
          <Menu
            id="simple-menu"
            anchorEl={anchorEl}
            keepMounted
            open={Boolean(anchorEl)}
            onClose={handleClose}
          >
            <MenuItem onClick={handleOpenModal}>Cambiar contraseña</MenuItem>
            <MenuItem onClick={handleLogout}>Cerrar sesión</MenuItem>
          </Menu>
        </Toolbar>
      </AppBar>
      <Hidden smUp={true}>
        <Drawer
          variant="temporary"
          open={open}
          classes={{
            paper: classes.drawerPaper,
          }}
          ModalProps={{
            keepMounted: true, // Better open performance on mobile.
          }}>
          {userAuth ? userAuth.sub ? drawer : null : null}
        </Drawer>
      </Hidden>
      <Hidden xsDown>
        <Drawer
          variant="permanent"
          classes={{
            paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
          }}
          open={open}
        >
          {userAuth ? userAuth.sub ? drawer : null : null}
        </Drawer>
      </Hidden>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />

        <Container maxWidth={"100%"} className={classes.container}>
          {userAuth && userAuth.sub && <Model modal={modal} handleCloseModal={handleCloseModal}>
            <ChangePassword handleCloseModal={handleCloseModal} userAuth={userAuth} />
          </Model>}
          <Grid container spacing={3}>
            <Grid item xs={12}>
              {/* {children} */}
              <SwitchRoutes />
            </Grid>
          </Grid>
        </Container>
      </main>
    </div>
  )
}


export default Layout

Layout.propTypes = {
  children: PropTypes.oneOfType([PropTypes.array, PropTypes.node])
}
