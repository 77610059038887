import React from 'react';
import PropTypes from 'prop-types';
import Documents from '../../loan-application/components/documents';
import { Box, CircularProgress, Fab, Grid } from '@material-ui/core';
import { Save as SaveIcon } from '@material-ui/icons';
import { toast } from 'react-toastify';
import { fetchMutationSign } from '../../../components/hooks/useApplicationSign';
import { useMutation } from 'react-query';
import { useHistory } from 'react-router-dom';
import SignPad from '../../../views/loan-application/components/signPad';

const Sign = props => {
    const { solicitud } = props;
    const navigate = useHistory();
    const [isSubmitting, setSubmitting] = React.useState(false);
    const [sign, setSign] = React.useState(null);
    const [mutate] = useMutation(fetchMutationSign, {
        onSuccess: () => {
            setSubmitting(false);
            toast.success("La firma se a remplazado exitosamente", {
                autoClose: 2000
            })
            setTimeout(() => {
                navigate.goBack();
            }, 1000);
        },
        onError: (err) => {
            setSubmitting(false);
            toast.error(err.response.data.Message, {
                autoClose: 5000
            })
        }
    })

    const saveHandler = (e) => {
        e.preventDefault();
        
        const toSave = {
            Id: solicitud.Id,
            Estatus: 0,
            Firma: sign
        }
        
        if(!sign) {
            toast.info("La firma es requerida para continuar con el proceso", {
                autoClose: 3000
            });
            return;
        }
        setSubmitting(true);
        mutate(toSave);
    }

    return (
        <>
            <Grid item xs={12}>
                {isSubmitting &&
                    <Box display="flex" justifyContent="center">
                        <CircularProgress size={60}/>
                    </Box>
                }
                <SignPad error={!sign} disabled={isSubmitting} onSignChanged={e => {
                    setSign(e);
                }}
                    onClear={clear => {
                        setSign(clear);
                    }} />
            </Grid>
            <Grid item xs={12} style={{ textAlign: "right", marginTop: 10}}>
                <Fab type="submit"
                    size="medium"
                    color="primary"
                    title="Grabar solicitud"
                    disabled={!sign || isSubmitting}
                    onClick={saveHandler}
                >
                    <SaveIcon />
                </Fab>
            </Grid>
        </>
    )
}

Sign.propTypes = {
    solicitud: PropTypes.object.isRequired
}

export default Sign;
