import { fetcherReactQuery } from '../../services/api/fetcher'
import { useQuery } from 'react-query'
const fetch = async (api) => {
    try{
        const response = await fetcherReactQuery(api)
        return response.data
    }catch(err)
    {
        return null
    }
}
const useLoanInfo = (empresaConvenioId, userId) => {
    return useQuery([`/api/registrarprestamosahorro/caratula/${empresaConvenioId}/${userId}`], fetch)
}
export default useLoanInfo

