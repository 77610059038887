import React from 'react'
import PropTypes from 'prop-types'
import { Button, Dialog, DialogActions, DialogContent, DialogTitle } from '@material-ui/core'

const Index = (props) => {
    const { children, modal, handleCloseModal, title, fullScreen, showFooter } = props    
    const handleClose = () => {
        handleCloseModal(false);
    }

    return (
        <Dialog fullScreen={fullScreen} open={modal} onClose={handleClose} aria-labelledby="form-dialog-title">
            <DialogTitle id="form-dialog-title">{title}</DialogTitle>
            <DialogContent>
                {children}
            </DialogContent>
            {showFooter && 
            <DialogActions>
                <Button onClick={handleClose} color="primary" variant={"contained"}>
                    Cancelar
                </Button>
            </DialogActions> }
        </Dialog>
    )
}

Index.defaultProps  = {
    fullScreen: false,
    showFooter: false,
    title: "Cambio de contraseña"
};

Index.propTypes = {
    modal: PropTypes.bool,
    handleCloseModal: PropTypes.func.isRequired,
    children: PropTypes.node,
    title: PropTypes.string,
    fullScreen: PropTypes.bool,
    showFooter: PropTypes.bool
}

export default Index
